/* ==========================================================================
10. Bestellübersicht
========================================================================== */

figure#nurDerLadePlatzhalter {
    background: #ccc;
    max-width: 300px;
    text-align: center;
    color: #fff;
    padding: 20px 30px 20px 10px;
    border-radius: 50px;
    position: relative;
}

figure.button#nurDerLadePlatzhalter:after {
    content: "";
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-top-color: #fff;
    -webkit-animation: spin 1s infinite linear;
    animation: spin 800ms infinite linear;
    display: block;
    position: absolute;
    top: 21px;
    right: 24px;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.zahlart span.box {
    text-transform: uppercase;
    color: $color-primary !important;
    line-height: 90%;
    font-size: 1.5rem;
    white-space: nowrap;
}

.zahlart span.box a,
.zahlart span.box b {
    font-weight: initial !important;
    color: initial !important;
    font-size: initial !important;
    text-transform: initial !important;
}

.zahlart span.box b {
    margin-top: 20px;
    display: block;
    color: #999 !important;
}

.zahlart span.box a {
    color: $color-primary !important;
}

@media (max-width: 576px) {
    .zahlart span.box {
        white-space: normal !important;
    }
}

a.versandart span {
    color: $dunkeltext;
    white-space: nowrap;
    font-size: 0.85rem;
}

table[style*="#DD2222"],
.PGerror,
.error_vor_absendenbutton {
    border: 1px solid $rot !important;
    padding: 18px;
    background: $rot;
    width: auto;
    margin: 6px auto;
}

table[style*="#DD2222"] *,
.PGerror *,
.error_vor_absendenbutton * {
    color: white;
    font-size: 16px;
    background: none;
}

img.achtung {
    height: 45px;
    margin-right: 20px;
}

.PGerror,
.error_vor_absendenbutton {
    padding-left: 60px;
    position: relative;
    display: block;
    color: white;
}

.PGerror img.achtung {
    margin: 0;
    position: absolute;
    left: 8px;
}

.mitte_login,
.mitte_login.abschluss {
    display: block !important;
}

.mitte_login.abschluss {
    .box {
        position: unset;
    }
}

.abschluss .order-info div.versandart span.box a {
    display: flex;
    gap: 30px;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    padding: 5px 15px;
    margin: 10px 20px 0 0;
    min-height: 38px;
    background-color: $hellgrau;
}

a.versandart span:last-child {
    margin-left: auto;
}

a.versandart span:not(:last-child) {
    white-space: normal;
}

a.versandart div {
    white-space: nowrap;
}

#abs_ala u {
    display: none;
}

.abschluss .order-info div.versandart span.box a[data-aktiv="1"] {
    border: 2px solid;
}

.abschluss .order-info div.versandart span.box a[data-aktiv="1"]:before {
    background: $color-primary;
    box-shadow: inset 0 0 0 2px white;
}

.abschluss .order-info div.versandart span.box a:before,
.versandart span.box div.teil label:before {
    content: "";
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 16px;
    border: 2px solid $color-primary;
    cursor: pointer;
    margin: auto 10px auto 0;
    flex-shrink: 0;
}

.abschluss .order-info div.versandart span.box a {
    margin: 20px 0 0 0;
}

.order-info .versandart {
    grid-column-end: span 2;
    order: 1;
}

.versandart img {
    max-width: 70px;
}

.mitte_login.abschluss table {
    border-spacing: 0;
    width: 100%;
}

.order-info {
    align-items: normal;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    background: #fff;
    justify-content: space-around;
    gap: 30px;
    padding: 20px;
    margin: 20px 0;
}

.order-info div.col > div {
    flex: 1;
}

.order-info div.col:last-child div {
    border: none;
}

.order-info a.edit {
    position: absolute;
    left: 0;
    bottom: -45px;
    font-size: 1rem;
    background: url(#{$cdnPfad}/b/stetson_icon_edit.svg) 0 0 no-repeat;
    padding: 1px 0 5px 25px;
    background-size: 20px;
    height: 30px;
}

.order-info div.col .versandart {
    min-width: 260px;
}

.order-info div p {
    font-size: 1.4rem;
    color: $dunkeltext;
    margin: 0 0 10px 0;
}

.order-info span.box .teil {
    display: none;
}

.order-info span.box {
    margin: 0 0 40px 0;
    display: block;
    position: relative !important;
}

.order-info span.box a {
    color: $color-primary;
}

.mitVersandart,
.zk_versandartenTD {
    display: none;
}

.order-info form select {
    font-family: Catamaran, Arial, sans-serif !important;
    font-size: 0.7rem !important;
    margin: 10px 20px 0 0 !important;
    height: 45px;
    max-width: 243px;
}

.login_o {
    text-align: left;
}
