#cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: $dunkeltext;
    width: 100%;
    display: none;
    justify-content: center;

    .cookie-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        max-width: 70%;
        margin: 20px auto;
        padding: 15px;

        h3 {
            color: #fff;
            font-size: 1.6em;
            margin: 0 0 20px 0;
        }

        p {
            color: #fff;
            margin: 0 0 20px 0;
        }

        .cookie-text {
            color: #fff;
            line-height: 150%;

            a {
                color: #fff;
                text-decoration: underline;
            }
        }

        .cookie-close {
            display: flex;
            justify-content: center;

            button,
            a {
                color: #fff;
                background-color: $color-primary;
                cursor: pointer;
                margin: 10px 30px;
                font-size: 1.2em;
                text-align: center;
                border: none;

                &.cookie-settings-button {
                    color: #ccc;
                    background: transparent;
                    border: 2px solid #ccc;
                    padding: 10px 40px;
                }

                &.cookie-confirm {
                    padding: 10px 80px;
                }
            }
        }
    }
}

.cookie-settings {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2147485647;

    h2 {
        font-size: 2em;
        font-weight: 700;
    }

    .cookie-seetings-buttons {
        display: flex;
        justify-content: space-between;

        .cookie-seetings-save,
        .cookie-seetings-reject {
            max-width: 50%;
            align-self: flex-end;
            margin: 20px 0 0 0;
            font-size: 1.2em;
        }
    }

    .cookie-settings-select {
        margin: 20px 0 0 0;
        max-height: 300px;
        overflow-y: scroll;
        position: relative;
        overflow-x: hidden;

        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 50px;
            background: rgb(255, 255, 255);
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            position: sticky;
            bottom: 0;
        }

        .cookie-select {
            display: flex;
            margin: 10px 0 0 0;

            > div {
                margin: 0 40px 0 0;
            }
        }
    }
}

@media (max-width: 1360px) {
    #cookie {
        .cookie-wrapper {
            flex-wrap: wrap;
            max-width: 75%;

            .cookie-close {
                width: 100%;
                justify-content: space-between;

                button,
                a {
                    margin: 10px 0;
                }
            }
        }
    }
}

@media (max-width: 760px) {
    #cookie {
        font-size: 0.5em;

        .cookie-wrapper {
            max-width: 95%;

            h3 {
                font-size: 2.5em;
                font-weight: 700;
                margin: 5px 0 30px 0;
            }

            .cookie-text {
                max-height: 80vw;
                overflow-y: scroll;
                position: relative;

                .headline {
                    font-size: 20px;
                    line-height: 22px;
                    margin-bottom: 15px;
                }

                p {
                    line-height: 140%;
                    font-size: 2em;
                }

                &:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 70px;
                    position: sticky;
                    bottom: 0;
                    box-sizing: border-box;
                    background: rgb(100, 100, 95);
                    background: linear-gradient(0deg, rgba(74, 74, 74, 1) 0%, rgba(74, 74, 74, 0) 100%);
                }

                br {
                    display: none;
                }
            }

            .cookie-close {
                button,
                a {
                    width: 100%;
                    font-size: 2em;

                    &.cookie-settings-button {
                        width: 35%;
                        padding: 10px 0px;
                    }

                    &.cookie-confirm {
                        width: 55%;
                        padding: 10px 0px;
                    }
                }
            }
        }
    }

    #cookie-settings {
        font-size: 0.5em;

        h3 {
            font-size: 1.7em;
        }

        p {
            font-size: 1.4em;
        }

        input.switch {
            font-size: 0.7em;
        }

        .cookie-seetings-buttons {
            flex-direction: column;

            button {
                max-width: none;
                font-size: 2em;
                width: 100%;
            }
        }

        .cookie-settings-select {
            max-height: 230px;
        }
    }
}
