$abschicken: 'Send';
$ab_text_1: '<b>Fast</b> Shipment';
$ab_text_2: '<b>Officially Licensed</b> Stetson Online Shop';
$ab_text_3: '<b>100-day</b> Return Policy';
$adresse: 'Address';
$adresse_aendern: 'Update address';
$aendern: 'Update';
$agb: 'Terms and conditions';
$agb_url: 'Terms-and-conditions.html';
$alles_auswaehlen: 'Select all';
$alle_preisangaben_inkl_mwst: 'All prices include VAT.';
$anderer_anlass: 'Other occasion ...';
$andere_sportart: 'Other sport ...';
$anlass: 'Special occasion';
$anmelden: 'Login';
$anmeldung: 'Registration';
$anzahl: 'Quantity';
$anzahl_der_artikel: 'Number of items:';
$an_rechnungsadresse_liefern: 'Ship to invoice address';
$artikel: 'items';
$artikel_kombination_derzeit: 'Item combination currently not available';
$artikel_nicht_verfuegbar: 'Item currently not available';
$artikel_zz_nicht_lieferbar: 'Item currently not available';
$art_nr: 'Item no.:';
$auf_der_wunschliste: 'Add to my wish list';
$auf_die_wunschliste: 'Add to my wish list';
$ausloggen: 'Log out';
$ausloggen_link: '/s01.php?shopid=s01&cur=eur&sp=en&ag=1&pp=login&lg=8';
$aus_derselben_serie: 'From the same product line';
$aus_welchem_hauptgrund_haben: 'For what main reason did you choose the items you ordered? *';
$benefit_azu_text_1013: 'Sustainable';
$benefit_azu_text_1049: 'Sustainable brand';
$benefit_azu_text_115: 'UV protection';
$benefit_azu_text_116: 'Water-<br>repellent';
$benefit_azu_text_117: 'Packable';
$benefit_azu_text_1243: 'Fleece lining';
$benefit_azu_text_1293: 'Touchscreen<br>gloves';
$benefit_azu_text_158: 'For kids';
$benefit_azu_text_1609: 'UV protection 15';
$benefit_azu_text_1610: 'UV protection 20';
$benefit_azu_text_1611: 'UV protection 30';
$benefit_azu_text_1612: 'UV protection 40';
$benefit_azu_text_1613: 'UV protection 50';
$benefit_azu_text_1614: 'UV protection 60';
$benefit_azu_text_1615: 'UV protection 80';
$benefit_azu_text_1635: 'UV protection';
$benefit_azu_text_1636: 'Unisexitem';
$benefit_azu_text_1754: 'ClimatePartner';
$benefit_azu_text_473: 'Wear it as a face mask';
$benefit_azu_text_566: 'Made in<br>Germany';
$benefit_azu_text_567: 'Made in<br>Italy';
$benefit_azu_text_568: 'Made in<br>USA';
$benefit_azu_text_617: 'Asahi Guard®';
$benefit_azu_text_618: 'COOLMAX®';
$benefit_azu_text_619: 'GORE-TEX®';
$benefit_azu_text_620: 'SympaTex®';
$benefit_azu_text_621: 'WINDSTOPPER®';
$benefit_azu_text_622: 'WOOLRICH®';
$benefit_azu_text_636: 'Made in<br>Spain';
$benefit_azu_text_637: 'Made in<br>the EU';
$benefit_azu_text_638: 'Handmade';
$benefit_azu_text_639: 'Free Stetson<br>hat box';
$benefit_azu_text_640: 'Comes in UGG gift box';
$benefit_azu_text_641: 'Washable<br>at 90 °C';
$benefit_azu_text_642: 'Washable<br>at 60 °C';
$benefit_azu_text_643: 'Washable<br>at 40 °C';
$benefit_azu_text_644: 'Washable<br>at 30 °C';
$benefit_azu_text_645: 'Hand wash';
$benefit_azu_text_646: 'Made in<br>Ecuador';
$benefit_azu_text_647: 'Made in<br>Mexico';
$benefit_azu_text_648: 'Made in<br>France';
$benefit_azu_text_649: 'Made in<br>Australia';
$benefit_azu_text_650: 'Made in<br>Austria';
$benefit_azu_text_651: 'Made in<br>Switzerland';
$benefit_azu_text_652: 'Made in<br>Nepal';
$benefit_azu_text_653: 'Made in<br>Ireland';
$benefit_azu_text_654: 'Made in<br>the UK';
$benefit_azu_text_655: 'Anti-rain spray<br>order here';
$benefit_azu_text_814: 'Made in<br>Scotland';
$benefit_azu_text_93: 'Serial article';
$benefit_hotline: 'Service Hotline<br>+49 251 322654249';
$benefit_lieferzeit: 'Fast<br>Shipping';
$benefit_rueckgabe: '100-day<br>right to cancel';
$benefit_service: 'Excellent Service<br>via Phone and E-mail';
$benefit_versand: 'Free Shipping<br>for Orders Over €100';
$beratung_bestellung: 'Advice & order';
$beschreibung: 'Product description';
$bestaetigen: 'Confirm';
$bestaetigungs_email: 'You will receive an order confirmation by email shortly.';
$bestellbestaetigung: 'Order confirmation';
$bestellnummer: 'Order number:';
$bestelluebersicht: 'Order overview';
$bestelluebersicht_link: '/s01.php?cur=eur&sp=en&ag=1&pp=bue';
$bestellungen_einsehen: 'You can also view your orders in your account at any time:';
$bestellung_vom: 'Order from';
$bewertung: 'Review';
$bic: 'BIC:';
$bitte_geben_sie_mindestens_ein: 'Please select at least one reason.';
$bitte_w_hlen_sie_eine_gr_e_far: 'Please choose your size and colour.';
$bleiben_sie_auf_dem_laufenden: 'Stay up to date by registering an account with us:';
$breadcrumb_start: 'Homepage';
$caps: 'Caps';
$cdn_pfad: 'https://cdn.stetson.eu';
$cookie_bestaetigen: 'Confirm';
$cookie_headline: 'The protection of your data is important to us.';
$cookie_satz_1: 'Stetson uses cookies and other technologies to make sure our online shop runs reliably and securely, and so that we can show you relevant content and personalised advertising. For this to work, we collect data about our users and how they access our offers on whatever devices they use.';
$cookie_satz_3: 'If you click “Confirm”, you agree to this and allow us to pass this data on to third parties, such as our marketing partners. If you do <a href=\"#\" data-all-cookie=\"false\">not agree</a>, only the essential cookies will be used. In this case, our content will unfortunately not be tailored to your interests. Further details and all options can be found under “Settings”. You can also change these settings at any time. Please also note the information in our <a href=\"Data-protection.html\">Privacy statement</a>.';
$cookie_settings: 'Settings';
$corsslayer-anrs: '62910,59072,61949,58341,40661,67663,50737,57088,59071,59739,66662';
$datenschutz: 'Privacy policy';
$datenschutz_einstellungen: 'Data protection settings';
$datenschutz_einstellungen_txt: 'Here you can allow or deny the tracking and analysis tools on our website.';
$datenschutz_headline: 'The protection of your data is important to us';
$datenschutz_url: 'Privacy-policy.html';
$delet_all_filters: 'Remove filters';
$derzeit_nicht_lieferbar: 'currently not available';
$details_bestellung: 'Details of your order';
$devlink_js_meili: 'http://127.0.0.1:5500/bin/js/meilisearch-en.js';
$die_naechsten_bewertungen: 'Show more reviews';
$eine_kurze_frage_h_tten_wir_no: 'We just have a quick question:';
$eingrenzen: 'FILTER';
$einstellungen: 'Settings';
$einstellungen_bestaetigen: 'Confirm settings';
$einzelpreis: 'Unit price: ';
$email: 'Email address:';
$email_adresse: 'Email address';
$fahrrad: 'Cycling';
$farbe: 'Colour';
$fashion: 'Fashion';
$favouriten_headline: '<span>Wish list</span><p>Welcome to your personal Stetson wish list. Here you can mark items that you particularly like for a later visit. Click on the heart symbol to add an item to your wish list and log in to your customer account so that the items you have selected will be saved there.</p><a href=\"/\" class=\"button\">Shop Now</a>';
$fav_cross_artikel: '30958,64066,67025';
$features_ul: '#replace#/<[\\\/]*(t|img|br)[^>]*>/###features_u#';
$filter: 'Filter';
$footer_links: '<a href=\"/Legal-notice.html\">Legal Notice</a>|<a href=\"/Terms-of-use.html\">Terms of Use</a>|<a href=\"/Data-protection.html\">Privacy Policy</a>';
$footer_links_ueber_stetson: '<a href=\"/About-us.html\">About Us</a><a href=\"/Legal-notice.html\">Legal Notice</a><a href=\"/Shipping.html\">Shipping Information</a>';
$geburtstag_hochzeit: 'Birthday, wedding, christening ...';
$gesamt: 'Total price';
$gesamtsumme: 'Total sum: ';
$gesamtsumme_inkl_mwst: 'Total sum (incl. 19% VAT)';
$geschenk: 'Gift';
$gratis_versand: '<strong>Low</strong> shipping<br>costs';
$groesse: 'Size:';
$groessentabelle: 'Size chart';
$groessentabelle_url: '/s01_hi.php?h=14&s=en';
$haarverlust: 'Hair loss';
$haendlerinformation: 'Detailed seller information';
$huete: 'Hats';
$iban: 'IBAN:';
$ihre_auswahl: 'Your choice: ';
$ihre_bestellungen: 'Your orders';
$ihre_bestellung_wird_verarbeit: 'Your order is being processed.';
$ihre_lieferadresse: 'Your shipping address';
$ihre_rechnungsadresse: 'Your invoice address';
$ihre_suche_ergab_leider_keine: 'Your search returns no results.';
$ihre_zahlart: 'Your payment method:';
$im_store_verfuegbar: 'Available in our store:';
$in_den_warenkorb: 'ADD TO SHOPPING BAG';
$kaelte: 'Cold';
$kategorien: 'Categories';
$keine_bestellungen: 'You do not have any orders yet.';
$kontakt: 'Contact';
$kontakt_support: 'Contact & support';
$kontakt_url: 'Contact.html';
$konto_erstellen: 'Create an account';
$kostuem: 'Costume';
$kreditkarte_logo: 'https://cdn.stetson.eu/b/lierys-kreditkarte-en.svg';
$kundenbewertungen: 'Customer reviews';
$k_nnte_ihnen_auch_gefallen: 'You might also like';
$lade: 'Loading';
$lager_wenige_vorhanden: 'Only # left';
$laufen: 'Jogging';
$lieferzeit: '<strong>Fast</strong><br>shipping';
$lierys_liste: '<li>Stetson combines well-known shapes with high-quality materials and functionality. But even unusual style elements are sometimes used as highlights to add the finishing touch to products. </li> <li>Many knitted hats and a range of extravagant headgear are designed and produced in Germany. </li> <li>Other headgear, such as trendy hats and visors, enchant with their Italian charm and bear the Made in Italy quality label. </li> <li>The iris on the logo symbolises innocence and purity. These values can be seen in the timelessly stylish designs of many hats, beanies and accessories from Stetson. </li>';
$lierys_zitat: 'To me, Stetson means combining trendy and new accents with timeless headgear. Everyone can add a breath of fresh air to their unique style, while the high quality of the products is always guaranteed. Classic designs with modern accents – it really hits the nail on the head! <footer> <cite>Andre Beelmann, Managing Director of ANTHEC GmbH & Co. KG</cite></footer>';
$link_agb: '/Terms-and-conditions.html';
$link_datenschutz: '/Data-protection.html';
$link_js_meili: 'https://js.stetson.eu/bin/js/meilisearch-en.js';
$link_kontakt: '/Contact.html';
$link_rueckgaberecht: '/Exchanges-returns.html';
$listenpreis_preis: '#listenpreis €';
$login_url: '/s01.php?cur=eur&sp=en&ag=1&pp=bue';
$marketing: 'Marketing';
$marketing_txt: 'Marketing cookies are used to show you relevant sponsored content about our products. We use information about your previous purchases and interaction with our website to select content that may be interesting to you. We may pass this information and a customer ID, such as an encrypted e-mail address or device data, on to third parties such as Facebook and Google in order to present you with relevant advertising on the websites and apps of third party providers. We may also link this data across devices and process data about the advertising displayed (e.g. whether you clicked on an advert and then purchased something) in order to measure the efficiency of our adverts.';
$mehr: 'More:';
$mehrere_antworten_m_glich: '* Multiple answers possible.';
$mehr_ohne_dp: 'more';
$mehr_von_stetson: 'More from Stetson';
$mehr_von_stetson_links: '<a href=\"https://stetson-europe.com/\" target=\"_blank\">Stetson-Europe.com</a><a href=\"https://stetson.com/\" target=\"_blank\">Stetson.com</a><a href=\"https://stetson-europe.com/shoplocator/\" target=\"_blank\">Shop Locator</a>';
$meili_artikel_gefunden: '# item(s) found';
$meili_fehler_in_suche: '<div>Error loading search results. Please try again later.</div>';
$meili_html_include_link: 's01.php?&sp=#sp&cur=eur&nla=&ag=1&pp=htm&htmnr=28';
$meili_keine_suchergebnisse: '<div>Unfortunately, we couldn´t find anything matching your search.</div>';
$meili_sorter_preis_absteigend: 'Highest price';
$meili_sorter_preis_aufsteigend: 'Lowest price';
$meili_sortieren: 'Sort';
$meili_sort_relevanz: 'Recommendation';
$meili_suche_leer: '<div>Please enter your search.</div>';
$menu_alle_accessoires: 'All Accessories';
$menu_alle_caps: 'All Caps';
$menu_alle_huete: 'All Hats';
$menu_alle_muetzen: 'All Beanies';
$menu_alle_schirmmuetzen: 'All Flat Caps';
$merkzettel_url: '/s01.php?sp=en&ag=1&pp=perart';
$muetzen: 'Beanies';
$nachhaltigkeit_1013: 'Sustainable';
$nachhaltigkeit_116: 'Water-repellent';
$nachhaltigkeit_117: 'Packable';
$nachhaltigkeit_1754: 'ClimatePartner';
$nachhaltigkeit_637: 'Made in the EU';
$naesse: 'Rain';
$neu: 'NEW';
$neuheiten_entdecken: 'Discover new arrivals';
$newsletter_abmeldung: 'Unsubscribe';
$newsletter_anmeldung: 'Newsletter subscription';
$newsletter_bedingungen: 'Security is very important to us! Of course, we guarantee the security of your data in accordance with data protection law. You can find more information on data protection at stetson.eu/Data-protection.html.';
$newsletter_benefits_1: 'Low shipping fee';
$newsletter_benefits_2: 'Fast delivery';
$newsletter_benefits_3: 'Hotline clients<br>+49 251 322654249';
$newsletter_benefits_4: 'Customer service<br>via phone & email';
$newsletter_content: 'Sign up for our Stetson newsletter and be the first to know about new items, special collections and exclusive pre-orders.';
$newsletter_footer_text: 'KART Holding Verwaltungs GmbH<br>Oskar-Schindler-Straße 11, 50769 Cologne, Germany<br>Internet: Stetson.eu, E-Mail: support@stetson.eu<br>Registered at Amtsgericht Köln - HRB 73976, Managing director: Sebastian Boekholt, VAT No.: DE314409519';
$newsletter_link_agb: 'https://stetson.eu/Terms-and-conditions.html';
$newsletter_link_datenschutz: 'https://stetson.eu/Data-protection.html';
$newsletter_link_impressum: 'https://stetson.eu/Legal-notice.html';
$newsletter_link_logo: 'https://stetson.eu/en.html?cur=eur';
$newsletter_lp: '#artikel_listenpreis €';
$newsletter_sprache: 'en';
$newsletter_src_siegel: 'https://cdn.stetson.eu/ul/stetson_siegel_en.png';
$newsletter_text_agb: 'Terms and conditions';
$newsletter_text_datenschutz: 'Data protection';
$newsletter_text_impressum: 'Legal notice';
$newsletter_vk_preis: '#artikel_preis €';
$nl_anmeldung_url: 'Subscribe-to-newsletter.html';
$notwendig_eigene_cookies: 'Necessary (own cookies)';
$notwendig_eig_cookies: 'Necessary (own cookies)';
$notwendig_eig_cookies_txt: 'The data collected through these services are needed to ensure the technical performance of our website, to provide basic shopping functions, to make shopping with us safe, and to prevent fraud. These cookies cannot be deactivated as our website cannot function properly without them.';
$nur_notwendige_cookies: 'Necessary cookies only';
$oder_bezahlen_mit: 'or pay with';
$one_size: 'One Size';
$paginierung_seite_von: 'Page #seite from #seiten';
$passwort: 'Password';
$passwort_vergessen: 'Forgot your password?';
$passwort_wdh: 'Repeat password';
$passwort_zur_cksetzen: 'RESET YOUR PASSWORD';
$payments: '<img src=\"#ueb#img_pfad#b/stetson_payment_mastercard.svg\" loading=\"lazy\"> <img src=\"#ueb#img_pfad#b/stetson_payment_visa.svg\" loading=\"lazy\"><img src=\"#ueb#img_pfad#b/stetson_payment_amazon-payments.svg\" loading=\"lazy\"> <img src=\"#ueb#img_pfad#b/stetson_payment_paypal.svg\" loading=\"lazy\"> ';
$paypal_logo: 'https://cdn.stetson.eu/paypal.svg';
$preis: 'Price';
$produktbeschreibung: 'Product description';
$produktdetails: 'Product details';
$rechnungsadresse: 'Invoice address';
$sale: 'SALE';
$schlie_en: 'Close';
$schreiben_sie_uns_eine_e_mail: 'Send us an email.';
$service: 'Customer Service';
$service_links: '<a href=\"/Hat-sizing-assistant.html\">Hat Sizing Assistant</a><a href=\"/Frequently-asked-questions.html\">FAQ</a><a href=\"/Contact.html\">Contact</a><a href=\"/Exchanges-returns.html\">Returns</a><a href=\"/Subscribe-to-newsletter.html\">Newsletter</a>';
$se_vk_preis: '#se_vk €';
$shopname_footer: 'Stetson.eu';
$siegel_img: '<img src=\"#ueb#img_pfad#b/stetson_siegel_en_GB.svg\">';
$sonne: 'Sun';
$sonstiges: 'Other reason...';
$sport: 'Sports';
$sprache-de: 'German';
$sprache-en: 'English';
$sprache-icon: 'lierys-icon-eu.svg';
$sprache-kurz: 'en';
$sprachewaehlen: 'Select Your Location';
$sprache_deutsch: 'DE';
$sprache_englisch: 'EN';
$startseite_headline_2: 'What makes our brand special?';
$startseite_headline_3: 'What can I expect from the Stetson range?';
$startseite_headline_seo: 'Stetson – timeless and elegant headgear with a sense of extravagance';
$startseite_seo_1: 'Are you looking for a beanie for everyday wear, a scarf for the cool winter time or a fascinator for your next party? Then we’ve got a hot tip for you: Have a look at the wide range of our Stetson brand! Here women and men of every age will find high-quality headgear in a wide variety of styles, including all the necessary accessories. There’s the right beanie, cap or hat to suit every style and every personal preference.';
$startseite_seo_2: 'This is precisely what we asked our Managing Director, the creator of Stetson';
$startseite_seo_3: 'For the trend-conscious woman, there are modern cloches and sun hats made of straw or linen for the warm summer months. Straw hats are particularly air-permeable, making them very comfortable to wear during this season. You can combat the winter cold by wearing a hat made of wool felt with a stylish knitted scarf or snood. Stetson headbands and earmuffs are particularly warm thanks to their high-quality cotton material and round off every outfit.';
$startseite_seo_4: 'Stylish men can find fancy pork pie hats, traveller hats, fedoras, trilbies or Panama hats. Those looking for something even more elegant can also find bowler hats or top hats in our range. Conversely, you will be right on the casual urban trend with our classic flat caps or other peaked caps. Alternatively, you can add a maritime sailor cap from Stetson to your collection. To make sure you keep your new favourite product looking its best for as long as possible, you can use a hat box from our range for storage.';
$startseite_seo_5: 'Finally, in addition to other models in children’s sizes, we also offer cute pompom hats for little ones, which stay on firmly and look enchanting. So nothing stands in the way of the next adventurous outing in the snow.';
$startseite_seo_6: 'What are you waiting for? Immerse yourself in the extravagant and timeless world of Stetson!';
$status: 'Delivery status';
$suchen: 'Search ...';
$summe: 'Sum:';
$teillieferung_nur_mit_dhl: 'Partial delivery only possible with DHL.';
$tel: 'Phone:';
$telnummer: '+49 251 93216969';
$text_copyright_mwst: '\"&#x00A9; 2024 - Stetson.eu | All prices include VAT but exclude postage.\"';
$trend_pers_nlicher_stil: 'Trend, personal style, ...';
$umtauschrecht: '<strong>100-day</strong><br>return policy';
$unsere_informative_hotline: 'Our customer hotline.';
$unternehmen: 'About Stetson.eu';
$unternehmen_links: '<a href=\"Ueber-uns.html\">About us</a><a href=\"/Shipping.html\">Shipping information</a>';
$url: '/en.html';
$urlaub: 'Holiday';
$url_kontaktformular: 'Contact.html';
$verkauf_durch: 'Sold by';
$versand: 'Shipping';
$versandadresse: 'Shipping address';
$versandkosten: 'Shipping costs';
$vers_tab_font_size: 13px;
$video: 'Video';
$video_abhaengikkeit: '#multifeld14#';
$video_link: 'https://www.youtube.com/embed/#multifeld14#';
$vielen_dank_fuer_ihren_einkauf: 'Thank you for shopping.';
$vielen_dank_f_r_die_teilnahme: 'Thank you very much for your participation!';
$vk_preis: '#vk €';
$vorkasse_logo: 'https://cdn.stetson.eu/b/lierys-vorkasse-en.svg';
$waehrung: 'EUR';
$waehrungs-symbol: '€';
$wandern: 'Hiking';
$warenkorb_url: '/s01.php?ag=1&sp=sprache&cur=eur&pp=wk';
$web_performance_und_analyse: 'Web performance and analysis';
$web_perf_und_analyse: 'Web performance and analysis';
$web_perf_und_analyse_txt: 'We use this data to continuously improve our websites and services, and to carry out business-relevant analyses of the shopping experience on our apps and websites.';
$weitere_artikel: 'You Might Also Like These Items';
$weiterlesen: 'Read more';
$weiter_mit_seite_aktuelleseite: 'Continue with page #aktuelleSeite of #MaxSeiten';
$wetterschutz: 'Weather protection';
$widerrufsrecht: 'Right to cancel';
$widerrufsrecht_url: 'Returns.html';
$wkl_artikel_die_ihnen_ebenso: 'You Might Also Like These Items';
$wkl_artikel_die_ihnen_eben_2: 'You Might Also Like These Items:';
$wkl_der_artikel: 'The item';
$wkl_weitershoppen: 'Continue shopping';
$wkl_wurde_in_den_warenkorb: 'has been added to your shopping bag.';
$wkl_zum_warenkorb: 'Go to shopping bag';
$wuenschen_sie_teillieferung: 'Do you wish a <span>free</span> partial delivery?';
$xmas-versandinfo-save: 'Our Christmas shipping! Order by Dec. 15 - Delivery by Dec. 23';
$xmas-versandinfos: 'Merry Christmas and a Happy New Year';
$zahlungsart: 'Payment method';
$zahlungsarten: 'Payment Methods';
$zahlungsart_aendern: 'Change payment method';
$ziellaender_shop: 'IE,NO,GR,CZ,SI,RO,BG,HR,HU,CY';
$zumArtikel: 'View item';
$zum_kontaktformular: 'Contact form';
$zur_bestelluebersicht: 'Order overview';
$zur_ck_zur_bersicht: 'Back to overview';$local_gulp: null;// helpers -> Müssen zuerst importiert werden
@import "helpers/variables";

// base
@import "base/main";
