/* ==========================================================================
04. SUCHERGEBNIS & ARTIKEL
========================================================================== */

ul.se li {
    list-style-type: none;
}

.artikel {
    background: #fff;
    transition: all 100ms ease-in-out;
    overflow: hidden;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    line-height: 0;
    list-style-type: none;
    text-align: left;
}

.artikel > div {
    width: 100%;
    position: relative;
    background: #fff;
}

li.artikel.notifyOnLoadedLL.geladen {
    opacity: 1;

    //transition: all 300ms ease-in-out;
}

// .artikel figure > div:before {
//     content: '';
//     display: block;
//     background-image: var(--bg-model);
//     background-size: cover;
//     opacity: 1;
//     height: 100%;
//     width: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 10;
// }

// .artikel:hover figure > div:before {
//     opacity: 0;
//     content: '';
//     display: block;
//     background-image: var(--bg-model);
//     background-size: cover;
//     height: 260px;
//     width: 284px;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: 10;
// }

.artikel figure > div {
    display: flex;
    width: 70%;
    justify-content: center;
}

figure > div {
    // das hier ist für PDP
    display: flex;
    width: 100%;
    justify-content: center;
    max-height: 480px;
    &.video3d {
        max-height: calc(100% + 2px)
    }
}

.artikel .azu {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 99;
    
    img {
       display: none !important;
       transition: all 200ms ease-in-out; 
    }

    .se-item-watchlist {
        display: inline-block !important;
        opacity: .5;
        cursor: pointer;
    }

}

@media (min-width: 769px) {
    .artikel:hover .azu {
        display: flex;
        flex-direction: column;
        gap: 8px;
        img {
            opacity: .5 !important;
            display: inline-block !important;
        }
    }
}

div[data-farbe="5"] {
    border: 1px solid #ddd !important;
}

.artikel:hover .azu,
.artikel.active .azu {
    img {
        transform: translateX(0);
        transition-delay: 200ms;  
    }
    
}

span.se_neu {
    position: absolute;
    left: 0;
    top: 20px;
    background: $color-primary;
    padding: 5px 8px;
    z-index: 10;
    color: #fff;
    z-index: 1001;
    line-height: 130%;
}

span.se_diff {
    background: $rot;
    transition: right 400ms ease-in-out;
}

.artikel .se_farben {
    width: 100%;
    margin: 0 auto;
    display: flex;
    opacity: 1;
    height: 28px;
}

.lief_gr_farbe1_k {
    float: none;
    background-size: contain !important;
}

.se_farben div {
    display: flex;
    flex-wrap: wrap;
}

.artikel .se_farben .lief_gr_farbe1_k {
    width: 15px;
    height: 15px;
    border-radius: 20px;
    margin: 4px;
    opacity: 1;
}

.artikel .se_farben .lief_gr_farbe1_k[style*="mehrfarbig"] {
    border: 0;
    background-size: cover;
}

.artikel .se_farben .lief_gr_farbe1_k[style="background: #FFFFFF;"] {
    border: 1px solid #ccc;
}

.artikel:hover .azu,
.artikel.active .azu {
    img {
        opacity: .5;
    }
}

.artikel:hover .se_fav:hover path {
    stroke: #d0021b;
    opacity: 1;
    transition: all 300ms ease-in-out;
}

.artikel .se_fav:hover path {
    animation: pulse 600ms infinite;
    transform-origin: center;
}

@-webkit-keyframes pulse {
    0% {
        transform: scale(0.9);
    }

    70% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(0.9);
    }
}

.artikel .new,
.artikel .sale {
    text-transform: uppercase;
    position: absolute;
    top: 7px;
    right: 0;
    padding: 3px 6px;
    background: $color-primary;
    color: #fff;
    transition: all 200ms ease-in-out;
}

.artikel .sale,
.aa-proz {
    background: #d84d4d;
}

.artikel .sale::after {
    content: attr(data-sale);
}

.artikel:hover .new,
.artikel:hover .sale {
    transform: translateX(110%);
}

.artikel #aa_rating {
    position: absolute;
    top: -30px;
    z-index: 2;
    width: 100px;
    margin: 0 auto;
    left: 0;
    right: 0;
}

span.rating_tab {
    line-height: 0;
}

.artikel #aa_rating .rat_stars i,
.artikel #aa_rating .rat_stars b,
.artikel #aa_rating .rat_stars u {
    width: 20px;
    height: 20px;
}

span.rating_tab .rat_stars i,
span.rating_tab .rat_stars b,
span.rating_tab .rat_stars u {
    width: 20px;
    height: 20px;
}

.artikel:hover #aa_rating {
    top: 10px;
    transition: all 200ms ease-in-out;
    transition-delay: 400ms;
    display: none !important;
}

.artikel .se_name {
    font-size: 1.3rem;
    margin: 15px 0 0 0;
    overflow: hidden;
    font-family: GT-America, sans-serif;
    color: $color-primary;
    line-height: 130%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    span {
        white-space: nowrap;
        text-overflow: ellipsis !important;
        overflow: hidden;
        font-size: 1rem;
        text-align: left;
    }
}

.artikel .se_name div {
    color: $helltext;
    font-size: 0.83rem;
    margin: 3px 0 0 0;
}

.artikel .se_preis {
    font-size: 1rem;
    margin: 10px 0 10px 0;
    font-weight: 400;
}

.artikel .se_preis span.red {
    color: $rot;
}

.artikel .se_preis span strike {
    color: $buttonborder;
    font-size: 1rem;
    margin-left: 10px;
}

.artikel a {
    text-decoration: none;
    color: $dunkeltext;
    font-weight: 300;
}

ul.list_se {
    display: flex;
    height: fit-content;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px 0;
    grid-row-start: 3;
    grid-column-end: 3;
}

ul.list_se li:nth-of-type(2) {
    animation-delay: 50ms;
}

ul.list_se li:nth-of-type(3) {
    animation-delay: 100ms;
}

ul.list_se li:nth-of-type(4) {
    animation-delay: 150ms;
}

ul.list_se li:nth-of-type(5) {
    animation-delay: 200ms;
}

ul.list_se li:nth-of-type(6) {
    animation-delay: 250ms;
}

ul.list_se li:nth-of-type(7) {
    animation-delay: 300ms;
}

ul.list_se li:nth-of-type(8) {
    animation-delay: 350ms;
}

.artikel img.artikel-img {
    width: 100%;
    // height: auto;
    // display: block;
    // max-height: 270px;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    transition: all 400ms ease-in-out;
    position: relative;
    align-self: center;
}

.artikel img.artikel-img.lade {
    opacity: 0;
    transform: rotateY(90deg);
    transition: all 300ms ease-in-out;
}

img.artikel-img.geladen {
    opacity: 1;
    transform: rotateY(0deg);
    transition: all 300ms ease-in-out;
}

.artikel figure:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: $artikel-bg;
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
}

.artikel .info {
    position: relative;
    z-index: 10;
    position: absolute;
    top: 11px;
    right: 0;
}

ul.cross_se {
    max-height: 400px;
    padding: 20px 20px 0 20px;
}

#aa_cross .artikel:hover .se_fav {
    right: 9px;
}

.artikel figure {
    /* outline:2px solid red; */
    position: relative;
    aspect-ratio: 1/1;
    display: flex;
    display: -ms-flexbox !important;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: #ffffff;
    padding: 0 10px;
    box-sizing: border-box; // Damit die Aspect Ratio greift
}

#pacerGoOnButton {
    order: 99999999;
    width: 100%;
}

#pacerGoOnButton .button {
    min-width: 390px;
    min-height: 60px;
    margin: 50px auto 70px auto;
    display: block;
    background: $color-primary;
    border: none;
    color: #fff;
    font-weight: 200;
    transition: all 200ms ease-in-out;
    font-size: 1.2rem;
    border-radius: 5px;
}

@media (max-width: 395px) {
    #pacerGoOnButton .button {
        min-width: initial;
    }
}

#pacerGoOnButton .button:hover {
    background: $color-primary;
    transition: all 200ms ease-in-out;
}

@keyframes levitate {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    100% {
        -webkit-transform: translate(0, 0.3rem);
        transform: translate(0, 0.3rem);
    }
}

@media (max-width: 768px) {
    .artikel .azu {
        top: 5px;
        right: 5px;
    }
}

@media (max-width: 760px) {
    ul.list_se {
        gap: 5px 0;
    }

    .se_unterteil {
        margin-bottom: 23px !important;
    }

    .artikel:hover > div {
        border: 2px solid #61a395;
        border-right: none;
        border-left: none;
        width: auto;
        position: relative;
        z-index: 40;
        box-shadow: none;
    }

    .artikel .se_preis span strike {
        font-size: 1.2rem;
    }
}

@media (max-width: 576px) {
    .se_unterteil {
        margin-bottom: 13px !important;
    }

    .artikel {
        figure::after {
            bottom: -10px;
        }

        .azu .se-item-watchlist {
            width: 25px;
            height: 25px;
        }

        .se_name span {
            font-size: 0.8rem;
        }

        .se_preis {
            font-size: 1rem;
            margin: 5px 0 0 0;
        }

        span.se_neu {
            top: 5px;
        }

        .se_name {
            margin: 5px 0 0 0;
        }

        .se_farben > div {
            gap: 5px;
            height: fit-content;
            margin-top: 5px;
        }

        .se_farben .lief_gr_farbe1_k {
            width: 20px !important;
            height: 20px !important;
        }
    }
}
