/* ==========================================================================
03. Breadcrumb & Sortierung
========================================================================== */

.tab_se_unten #bread,
.tab_se_unten #dd_sort,
.tab_se_unten .anzahl {
    display: none;
}

.BreadcrumbList {
    border-bottom: 1px solid $hellgrau;
    display: flex;
    align-items: center;
    padding: 30px 0 20px 0;
    flex-wrap: wrap;
}

.top_bread-sort {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    min-height: 65px;
    grid-column: 2;
    padding: 10px 0 10px 0;
    grid-row-start: 2;
}

.top_bread-sort .tab_k .BreadcrumbList {
    border: none;
}

.BreadcrumbList span h1 {
    font-size: 1rem;
    font-family: "GT-America", Arial, sans-serif;
}

.BreadcrumbList span a {
    color: $color-primary;
}

.BreadcrumbList span:last-child a,
.BreadcrumbList span:nth-child(6) a,
.BreadcrumbList span:nth-child(8) a,
.BreadcrumbList span:nth-child(10) a,
.BreadcrumbList span:nth-child(12) a,
.BreadcrumbList span:nth-child(14) a {
    color: $color-primary;
}

.BreadcrumbList span:last-child a span {
    color: $dunkeltext !important;
}

a.location_crumb.location_crumb2.location_aug {
    color: $color-primary;
}

span.location_zk1,
span.location_aug,
span.location_aug2,
span.location_azu,
span.location_sk2,
span.location_sk1,
span.location_zk2,
span.location_her,
span.breadcrum_arrow {
    margin: 0 10px;
}

.breadcrum_arrow + .location_her,
.breadcrum_arrow + .location_azu,
.breadcrum_arrow + .location_aug,
.breadcrum_arrow + .location_aug2,
.breadcrum_arrow + .location_zk1,
.breadcrum_arrow + .location_zk2,
.breadcrum_arrow + .location_sk2,
.breadcrum_arrow + .location_sk1 {
    background-image: none;
    display: none;
}

div#blaettern_oben {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    z-index: 12;
    min-width: 230px;
    position: relative;
    min-height: 30px;
    z-index: 15;
}

.select_sortierung_se_top {
    list-style: none;
    max-height: 40px;
    z-index: 30;
    overflow: hidden;
    border-radius: 20px;
    margin: 0 0 0 10px;
    cursor: pointer;
}

.select_sortierung_se_top > li {
    padding: 10px 30px;
    width: min-content;
}

#dd_sort_top ul li a {
    white-space: nowrap;
}

.select_sortierung_se_top.open {
    overflow: visible;
}

.select_sortierung_se_top.open > li {
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 10px 30px;
    width: auto;
    border-radius: 5px;
}

@media (max-width: 385px) {
    .select_sortierung_se_top.open {
        right: -10px;
        width: 100%;
    }
}

.select_sortierung_se_top li ul {
    list-style: none;
    width: 0;
}

.select_sortierung_se_top.open li ul {
    width: auto;
}

.select_sortierung_se_top li ul li {
    padding: 5px 0;
}

.select_sortierung_se_top li ul li:first-child {
    padding-top: 14px;
}

.select_sortierung_se_top li ul li:hover a {
    color: $color-primary;
}

.dd_sort_top_kopf {
    display: flex;
    justify-content: start;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid $hellgrau;
    margin: -2px 0 0 0;
}

.dd_sort_top_kopf a {
    display: inline;
    height: 40px;
    line-height: 37px;
    padding: 0 10px 0 0;
    box-sizing: border-box;
    white-space: nowrap;
}

.dd_sort_top_kopf:hover a,
.select_sortierung_se_top.open .dd_sort_top_kopf a {
    color: $color-primary;
}

.dd_sort_top_kopf:after {
    content: " ";
    display: inline-block;
    background: url(#{$cdnPfad}/b/stetson_icon_arrow_down.svg) no-repeat (50% / contain);
    height: 10px;
    width: 15px;
    -webkit-transition: transform 0.5s;
}

.anzahl {
    color: $color-primary;
    right: 350px;
    text-align: right;
    height: 24px;
}

@media (max-width: 960px) {
    .top_bread-sort {
        flex-direction: column;
        align-items: flex-start;
    }

    .tab_k {
        order: -1;
    }
}
