/* ==========================================================================
05. _FILTER
========================================================================== */

.li_sort_see_nrauf,
.li_sort_see_nrab,
.li_sort_see_az,
.li_sort_see_za {
    display: none;
}

#dd_farb .see_li > .see_multi_label {
    gap: 12px;
    margin-left: 1px;
    align-items: center;
    line-height: 140%;
}

#dd_farb .see_li {
    margin-left: 1px;
}

.see_li_fa label input:after {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    visibility: visible;
    border-radius: 30px;
}

.see_li_fa label {
    color: #ccc;
}

/* Microsoft Edge Browser 12+ (All) - @supports method */

@supports (-ms-ime-align: auto) {
    .see_li_fa label {
        color: $dunkeltext;
    }
}

.see_li_fa label input#see_fa_15:after {
    background: url(#{$cdnPfad}/camouflage.svg) 0 0 no-repeat;
    background-size: cover;
}

.see_li_fa label input#see_fa_14:after {
    background: orange;
}

.see_li_fa label input#see_fa_13:after {
    background: #666;
}

.see_li_fa label input#see_fa_12:after {
    background: #fff;
    outline: 1px solid #ccc;
}

.see_li_fa label input#see_fa_11:after {
    background: black;
    color: #fff;
}

.see_li_fa label input#see_fa_10:after {
    background: $rot;
}

.see_li_fa label input#see_fa_9:after {
    background: #ff54d7;
}

.see_li_fa label input#see_fa_8:after {
    background: url(#{$cdnPfad}/b/stetson_bunt.svg) 0 0 no-repeat;
    background-size: cover;
}

.see_li_fa label input#see_fa_7:after {
    background: #9a00bb;
}

.see_li_fa label input#see_fa_6:after {
    background: $color-primary;
}

.see_li_fa label input#see_fa_5:after {
    background: #999;
}

.see_li_fa label input#see_fa_4:after {
    background: #ffe500;
}

.see_li_fa label input#see_fa_3:after {
    background: #a27b00;
}

.see_li_fa label input#see_fa_2:after {
    background: #006ed4;
}

.see_li_fa label input#see_fa_1:after {
    background: beige;
}

.tab_see {
    padding: 0 20px 0 0;
    position: relative;
    z-index: 12;
    transition: all 300ms ease-in-out;
    grid-row-start: 3;
}

#bread:hover #filter_anzeigen div.toggle_filter {
    background: $color-primary;
}

#bread {
    cursor: pointer;
    color: $helltext;
}

.tab_see .see_alle_dd li {
    label {
        color: $color-secondary;
    }

    &:hover > label {
        color: $color-primary;
    }
}

ul#dd_farb input:before {
    display: none;
}

ul#dd_farb .dd_list input[type="checkbox"]:checked:after {
    border: 4px solid #fff;
    width: 23px;
    height: 23px;
    box-shadow: 0 0 0 2px $color-secondary;
}

.tab_see button.see_multi_submit.mode-close {
    display: none !important;
}

figure#filter_anzeigen {
    display: none;
    align-items: center;
}

#filter_anzeigen div.toggle_filter {
    transition: background 0.5s;
    margin-left: 10px;
    width: 33px;
    height: 20px;
    box-sizing: border-box;
    padding: 2px;
    background: $color-primary;
    float: right;
}

#filter_anzeigen div:before {
    transition: margin 0.5s;
    content: " ";
    display: block;
    float: left;
    height: 16px;
    width: 16px;
    background: white;
}

#filter_anzeigen.filter_toggle div.toggle_filter {
    background: $helltext;
}

#filter_anzeigen.filter_toggle div:before {
    float: right;
}

.see_ueberschrift {
    width: 50px;
    height: 50px;
    background: url(#{$cdnPfad}/b/stetson_icon_close.svg) no-repeat 50%;
    display: none;
    float: right;
}

form[name="dd_aug_form"] {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
}

form[name="dd_aug_form"] ul:nth-of-type(1) li {
    z-index: 23;
}

form[name="dd_aug_form"] ul:nth-of-type(2) li {
    z-index: 22;
}

form[name="dd_aug_form"] ul:nth-of-type(3) li {
    z-index: 21;
}

form[name="dd_aug_form"] ul:nth-of-type(4) li {
    z-index: 20;
}

form[name="dd_aug_form"] ul:nth-of-type(5) li {
    z-index: 19;
}

form[name="dd_aug_form"] ul:nth-of-type(6) li {
    z-index: 18;
}

form[name="dd_aug_form"] ul:nth-of-type(7) li {
    z-index: 17;
}

form[name="dd_aug_form"] ul:nth-of-type(8) li {
    z-index: 16;
}

form[name="dd_aug_form"] ul:nth-of-type(9) li {
    z-index: 15;
}

form[name="dd_aug_form"] ul:nth-of-type(10) li {
    z-index: 14;
}

form[name="dd_aug_form"] a {
    color: $helltext;
}

form[name="dd_aug_form"] ul {
    list-style: none;
    cursor: pointer;
}

form[name="dd_aug_form"] > ul {
    position: relative;
}

form[data-anzahl-filter="4"] > ul {
    flex: 1 0 calc(25% - 10px);
}

form[name="dd_aug_form"] > ul > li {
    background: #fff;
    position: relative;
    z-index: 10;
    overflow: hidden;
    box-shadow: none;
    transition:
        max-height 300ms ease-in-out,
        box-shadow 200ms ease-in-out,
        border-color 200ms ease-in-out;
}

form[name="dd_aug_form"] > ul > li.active {
    max-height: 351px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-color: $color-primary;
    transition:
        max-height 300ms ease-in-out,
        box-shadow 200ms ease-in-out,
        border-color 200ms ease-in-out;
    width: 100%;
    padding-bottom: 10px;
}

form[name="dd_aug_form"] > ul .dd_list {
    display: block !important;
}

.see_multi_label {
    float: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 130%;
    white-space: nowrap;
    padding: 0 12px 0 0;
}

form[name="dd_aug_form"] > ul li.active .dd_list {
    position: relative;
    z-index: 1;
    padding: 35px 10px 0 10px;
    box-sizing: border-box;
    margin: -24px 0 0 0;
}

.see_alle_dd {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.se_li.se_li_such {
    height: 45px !important;
    margin: 10px 10px 0 0;
    display: none;
}

.se_li.se_li_such input {
    width: 98%;
    background: white url(#{$cdnPfad}/icon_search.svg) no-repeat 93% 50%;
    background-size: 7%;
}

#dd_zk1 .se_li_such {
    display: none;
}

form div[class*="_kopf"] {
    position: relative;
    z-index: 2;
    background: #fff;
    pointer-events: none;
}

form div[class*="_top_kopf"] {
    border: none;
    background: transparent;
}

form div[class*="_kopf"] a {
    width: 100%;
    display: block;
    font-size: 1rem;
    line-height: 260%;
    box-sizing: border-box;
    color: $color-secondary;
    font-family: "GT-America-Medium", sans-serif;
}

.dd_list ul {
    max-height: 245px;
    overflow: auto;
}

.see_li.active label:before {
    background: $color-primary url(#{$cdnPfad}/dropdown-arrow.svg) no-repeat 50% / 10px 10px;
}

.see_li:hover {
    background: transparent;
    cursor: pointer;
}

.dd_list ul::-webkit-scrollbar {
    width: 8px;
}

.dd_list ul::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #eee;
}

.dd_list ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $grey-dark;
}

.see_multi_submit {
    height: 35px;
    text-transform: uppercase;
    font-family: GT-America-Medium, Arial, sans-serif;
    color: #fff;
    margin-top: 10px;
    background: $color-primary;
    border: none;
    border-radius: 0;
}

.see_multi_submit:hover {
    background: $color-primary;
    color: #fff;
}

.see_li_fa_pic {
    width: 55px;
    height: 30px;
    border-radius: 20px;
    border: 1px solid #fff;
    margin-left: -43px;
}

.see_li_fa_pic[style*="background-color:#FFFFFF"] {
    border: 1px solid $hellgrau;
}

.see_multi_loesch_buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;
    margin: 0;
}

.see_multi_loesch_txt,
.see_multi_loesch_form {
    float: none;
    margin: 5px 0;
}

.see_multi_loesch,
#ps-anti-filter .see_multi_label,
#ps-filter-reset {
    padding: 7px 25px 7px 10px;
    background: url(#{$cdnPfad}/b/stetson_icon_close_w.svg) no-repeat 50%;
    background-size: 10px 10px;
    background-position: right 10px center;
    margin: 0;
    font-weight: 100;
    font-family: "GT-America";
    border: none;
    background-color: $color-primary;
    border-radius: 0;
    color: #fff;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
}

.see_multi_loesch:hover {
    background-position: right 12px center !important;
}

.see_multi_loesch.see_multi_loesch_alle {
    background-color: $color-primary !important;
    white-space: nowrap;
}

@media all and (max-width: 576px) {
    form[name="dd_aug_form"] > ul > li.active {
        position: relative;
    }
}

// Filter Reihenfolge
ul#dd_aug li .dd_list > ul {
    display: flex;
    flex-direction: column;
}

@mixin set-order($elements) {
    @for $i from 1 through length($elements) {
        #{nth($elements, $i)} {
            order: $i;
        }
    }
}

// Beispielverwendung
@include set-order(("#dd_aug2,#dd_aug", "#dd_sk1", "#dd_sk2", "#dd_zk1", "#dd_zk2", "#dd_f5", "#dd_groe", "#dd_farb", "#dd_f1", "#dd_f2", "#dd_f3", "#dd_f4"));

li.see_li.see_li_aug.see_aug_1 {
    order: 1;
}

li.see_li.see_li_aug.see_aug_2 {
    order: 2;
}

li.see_li.see_li_aug.see_aug_3 {
    order: 3;
}

li.see_li.see_li_aug.see_aug_4 {
    order: 4;
}

li.see_li.see_li_aug.see_aug_5 {
    order: 5;
}

.toggle_filter {
    display: none;
}

#ps-menu button.see_multi_submit {
    display: none !important;
}

#ps-menu ul.active button.see_multi_submit {
    display: block !important;
}

@media screen and (max-width: 960px) {
    label input[type="checkbox"]:checked::before,
    label input[type="checkbox"]:not(:checked)::before,
    label input[type="radio"]:checked::before,
    label input[type="radio"]:not(:checked)::before {
        margin: 2px 0.5em 4px 0 !important;
    }

    label.see_multi_label.see_multi_label_aug2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        padding: 0;
        width: 100%;
    }

    label.see_multi_label {
        max-width: fit-content;
    }

    #ps-menu {
        display: none;
    }

    #ps-suchleiste.hatFiltermoeglichkeiten #ps-filter-toggle {
        display: block !important;
    }

    .shop_tab_mitte {
        display: flex !important;
        flex-direction: column;
    }

    #ps-menu.open {
        display: block;
        left: 0 !important;
        transition: left 300ms ease-in-out !important;
    }

    #ps-menu-hintergrund.open {
        position: fixed;
        top: 90px;
        right: 10px !important;
        left: unset !important;
        bottom: unset !important;
        width: 40px;
        height: 40px;
        z-index: 1000000000 !important;
        display: block !important;
        background: url(#{$cdnPfad}/b/stetson_icon_close.svg) 50% no-repeat;
        background-color: unset !important;
    }

    div#blaettern_oben {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    figure#filter_anzeigen {
        display: block;
        color: $color-primary;
        padding: 10px 50px;
        border: 2px solid $color-primary;
        border-radius: 5px;
        font-size: 1.4rem;
    }

    .dd_sort_top_kopf a {
        color: $color-primary;
        font-size: 1.4rem;
        padding: 0;
        margin-left: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .anzahl {
        display: block;
        color: $color-primary;
        border-radius: 5px;
        font-size: 1.4rem;
        white-space: nowrap;
    }

    nav.tab_see,
    #ps-menu {
        position: fixed !important;
        top: 0;
        left: -120vw !important;
        width: 100% !important;
        height: 100dvh;
        z-index: 1000000000;
        background: $hellgrau;
        box-sizing: border-box;
        padding: 0;
        overflow: auto;

        .see_alle_dd {
            display: none;
        }

        .dd_list ul {
            display: grid;
            gap: 0.7em 0.3em;
            grid-template-columns: 1fr 1fr;
            max-height: none;
            overflow: unset;
        }

        #dd_zk1 .dd_list ul,
        #dd_zk2 .dd_list ul {
            flex-direction: row;
        }

        li.see_li.see_li_fa {
            width: 90px;
        }

        &.filter_toggle {
            left: 0 !important;
            transition: left 300ms ease-in-out;

            .see_ueberschrift {
                display: block;
            }
            .see_alle_dd {
                display: flex;

                form[name="dd_aug_form"] {
                    gap: 0;
                }
            }
        }

        form[name="dd_aug_form"] > ul {
            padding: 20px;
        }

        form[name="dd_aug_form"] > ul > li {
            background: $hellgrau;
        }

        form div[class*="_kopf"] {
            background: $hellgrau;
            border-bottom: 1px solid #fff;
            margin: 0 0 15px 0;
        }

        .see_li_fa label input:after {
            width: 60px;
            height: 60px;
        }

        ul#dd_farb .dd_list input[type="checkbox"]:checked:after {
            border: 4px solid #fff;
            width: 53px;
            height: 53px;
            box-shadow: 0 0 0 2px #3c3737;
        }

        .see_multi_loesch_buttons {
            padding: 0 20px;
            color: $color-primary;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .see_multi_loesch_txt {
            flex: 1 1 100%;
        }
    }

    #ps-menu {
        top: 70px;
    }
}

@media all and (max-width: 576px) {
    nav.tab_see {
        .dd_list ul {
            gap: 0.7em;
            grid-template-columns: 1fr;
        }
    }
}
