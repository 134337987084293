.mobilizedMenue-MainContaner {
    .lvl_3 {
        > li {
            flex: 1 0 50%;
            padding: 8px 0;

            > a {
                padding: 0 0 0 10px;
            }
        }
    }

    ul:not(.men_aug) {
        .men_aug_5 {
            // Accessoires (zu langes Wort)
            a:after {
                display: none;
            }
        }
    }
}

.mobile_menu,
.mobile_search {
    display: none !important;
}

li.mobilizedMenue-close {
    position: fixed;
    top: 0;
    background: $color-secondary;
    height: 50px;
    z-index: 10;
    width: 100%;
}

.mobilizedMenue-MainContaner {
    position: fixed;
    top: 88px;
    left: 0;
    transform: translate(0, 0);
    z-index: 100000;
    width: 100%;
    height: 100%;
    overflow: auto;
    transition: transform 0.3s;
}

@keyframes mobilizedMenue-Silde-Anim {
    0% {
        transform: translate(-100%, 0%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}

.mobilizedMenue-slide {
    position: absolute;
    top: 0;
    left: 0;
    animation-duration: 0.3s;
    animation-name: mobilizedMenue-Silde-Anim;
    animation-timing-function: linear;
    background: $color-secondary;
    height: calc(100dvh - 80px);
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.mobilizedMenue-MainContaner ul:first-child {
    flex: 0 0 auto;
    flex-direction: row-reverse;
}

.mobilizedMenue-MainContaner ul:first-child li:only-child {
    flex-basis: 100%;
}

.mobilizedMenue-MainContaner ul:first-child li a {
}

.mobilizedMenue-MainContaner ul {
    display: flex;
    flex-direction: column;

    &.lvl_3 {
        flex: inherit;
    }
}

.mobilizedMenue-MainContaner ul li {
    flex: 1;
    display: flex;
}

ul.mobilizedMenue-bottom {
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li a {
    color: #fff;
}

/*
ul.men_aug li {
    flex: 1 0 auto;
}
*/

ul.men_aug2.lvl_3 {
    /*flex: 1 0 auto;*/
    flex-wrap: wrap;
    flex-direction: row;
}

/*
ul.men_aug2.lvl_3 li {
        flex: 1 0 50%;
}
*/

ul.men_aug2.lvl_3 li a {
    font-size: 1.1rem;
}

ul.men_aug2.lvl_3 li.men__h.head,
ul.men_aug2.lvl_3 li.men_aug2_h.head {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    font-size: 1.2rem;
    max-height: 40px;
    min-height: 40px;
    line-height: 98%;
    padding: 5px 0 25px 50px;
    box-sizing: border-box;
}

ul.men_aug2.lvl_3:empty {
    display: none;
}

.mobilizedMenue-MainContaner.closed {
    transition: transform 0.3s;
    transform: translate(-100%, 0);
}

.mobilizedMenue-MainContaner ul,
.mobilizedMenue-MainContaner li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: "GT-America-Light", sans-serif;
}

.mobilizedMenue-MainContaner li a,
.mobilizedMenue-MainContaner li {
    font-size: 25px;
    color: #fff;
}

.mobilizedMenue-MainContaner li.head {
    text-align: center;
    padding: 15px 0 7px 0;
    color: $color-primary;
}

.mobilizedMenue-MainContaner li a {
    padding: 0 0 0 35px;
    position: relative;
    display: block;
    width: 85%;
    box-sizing: border-box;
}

.mobilizedMenue-MainContaner li.head {
    color: #fff;
    border: none;
}

.mobilizedMenue-MainContaner .men_aug2.lvl_3.formen {
    order: 1;
}

.mobilizedMenue-MainContaner .men_aug2.lvl_3.material {
    order: 2;
}

.mobilizedMenue-MainContaner .men_aug2.lvl_3.witterung {
    order: 3;
}

.mobilizedMenue-MainContaner li a:after {
    content: " ";
    background: no-repeat 50% 50%;
    background-size: contain;
    width: 20px;
    height: 20px;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    text-align: center;
}

.mobilizedMenue-MainContaner li a:after:hover + .submenu {
    display: block;
}

.mobilizedMenue-MainContaner li.mobilizedMenue-all a:after {
    background-image: url(#{$cdnPfad}/b/icon_breadcrum_arrow.svg);
    right: 13px;
}

.mobilizedMenue-MainContaner li.mobilizedMenue-back a:after {
    background-image: url(#{$cdnPfad}/b/icon_breadcrum_arrow.svg);
    transform: translateY(-50%) rotate(180deg);
    left: 7px;
}

.mobilizedMenue-MainContaner li.mobilizedMenue-close a {
    color: $color-secondary;
    width: 100%;
    margin-right: 25px;
}

.mobilizedMenue-MainContaner li.mobilizedMenue-close a:after,
.mobilizedMenue-MainContaner li.hasChildren:not(.mobilizedMenue-all) a:after {
    background-image: url(#{$cdnPfad}/b/stetson_icon_close_w.svg);
    left: initial;
    right: 0;
}

.mobilizedMenue-MainContaner li.hasChildren:not(.mobilizedMenue-all) a:after {
    background-image: url(#{$cdnPfad}/b/stetson_icon_arrow_w.svg);
}

.mobilizedMenue-MainContaner ul.sub-extra {
    display: none;
}

.mobilizedMenue-MainContaner li a,
.mobilizedMenue-MainContaner li {
    font-size: 1.5rem;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li a:after {
    content: none;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li:nth-of-type(4) a:before {
    background: transparent url(#{$cdnPfad}/icon_stores_w.svg) no-repeat 50% 50%;
    background-size: contain;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li:nth-of-type(3) a:before {
    background: transparent url(#{$cdnPfad}/icon_service_w.svg) no-repeat 50% 50%;
    background-size: contain;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li:nth-of-type(2) a:before {
    background: transparent url(#{$cdnPfad}/icon_fav_w.svg) no-repeat 50% 50%;
    background-size: contain;
}

.mobilizedMenue-MainContaner ul.mobilizedMenue-bottom li:nth-of-type(1) a:before {
    background: transparent url(#{$cdnPfad}/icon_login_w.svg) no-repeat 50% 50%;
    background-size: contain;
}

button.mobilizedMenue {
    display: block;
    float: none;
    background: transparent url(#{$cdnPfad}/icon_menu.svg) no-repeat 50% 50%;
    box-shadow: none;
    z-index: 1000;
    border: none;
    background-size: contain;
    padding: 0;
    border-radius: 0;
}

#mobileMenue {
    width: 100%;
    height: 100%;
    position: absolute;
}

.mobilizedMenue-MainContaner li.head {
    padding: 10px 0 10px 35px;
    background: $grey-dark;
}

.mobilizedMenue-MainContaner li.head.keintext{
    display: none;
}

#men_1 section.unten {
    position: absolute;
    left: -9999999px;
}

.mobilizedMenue-stores {
    display: none !important;
}

li.mobilizedMenue-fav {
    display: none !important;
}

@media (min-width: 761px) {
    ul.men_aug2.lvl_3.equipment,
    ul.men_aug2.lvl_3.highlights {
        flex-direction: column;
    }
}

#mobilizedMenue_1-MainContainer {
    ul.lvl_1 {
        margin-top: 60px;
    }

    .lvl_1 > li {
        border-bottom: 1px solid #666;
        padding: 20px 0;

        &:after {
            content: "";
            width: 30px;
            height: 30px;
            background: url(#{$cdnPfad}/b/stetson_arrow_mobile_w.svg) no-repeat 50% 50%;
            position: absolute;
            background-size: contain;
            right: 20px;
        }

        &.active {
            flex-direction: column;
            &:after {
                transform: rotate(90deg);
            }
        }
    }

    li.azu_112:after,
    li.azu_10:after {
        content: unset !important;
    }

    .submenu {
        position: relative !important;
        background: unset !important;
        padding: 0;

        .lvl_2 {
            flex-direction: column;
            gap: 20px;
            margin: 20px 0 10px 0px;

            li {
                flex: 1 0 auto;
                align-items: flex-start;
                font-size: 1.5rem;
            }
        }
    }
    .aug.active .submenu {
        display: flex;
        flex-direction: column;
        border: 0;
        margin: 20px 0;
        padding: 0 0 10px 0px;
        gap: 30px;
    }
}

ul.mobilizedMenue-bottom li {
    padding: 20px 0;
}

li.mobilizedMenue-login {
    border-bottom: 1px solid #666;
}

ul.lang {
    img {
        width: 40px;
        height: 30px;
    }

    > li {
        display: flex;
        flex-direction: column;
        padding: 20px 0 20px 35px;
        border-bottom: 1px solid #666;
        cursor: pointer;
    }
    &.active .submenu {
        display: flex;
        flex-direction: column;
        border: 0;
        margin: 30px 0 0 0;

        > img {
            display: none;
        }
    }

    .flaggen {
        display: flex;
        flex-direction: row;
        gap: 50px;
        flex-wrap: wrap;
    }

    a {
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        width: auto;
    }
}
