// Modelbilder im SE

ul.se:not(.filter_farbe) .artikel.modelBildAnzeigen:hover figure:after {
    opacity: 1;
}

body.zk1_17 .artikel.modelBildAnzeigen .male figure:after,
body.zk1_18 .artikel.modelBildAnzeigen .female figure:after,
body.filter-farbe .artikel.modelBildAnzeigen figure:after {
    content: unset !important;
}

ul.se li.modelBildAnzeigen figure:after {
    opacity: 0;
    transition: opacity 100ms ease-in-out;
    transition-delay: 100ms;
    content: " ";
    display: block;
    background-image: var(--bg-model);
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
}

ul.se {
    .se_beobachten,
    .rating {
        filter: brightness(0.9);
    }
}

// ENDE Modelbilder im SE
