#aa_detail.gutschein-seite {
    display: grid;
    grid-template-areas: "header header header" "left center right";
    grid-auto-columns: 1fr 2fr 1fr;
    gap: 30px;
    margin: 30px 0;

    #aa_name {
        grid-area: header;
    }

    #basket-wrapper {
        grid-area: right;
    }

    #aa_links {
        grid-area: left;
    }

    #aa_mitte {
        grid-area: center;
    }

    .appearance,
    .contribution {
        margin: 0 0 30px 0;
    }

    .contribution {
        display: grid;
        gap: 6px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    button {
        white-space: nowrap;
    }

    button.gutscheinbetr.gutscheinbetr-checked {
        background: #4fa085;
    }

    .wk-button {
        margin: 0 0 20px 0;
    }

    .aa_price2 {
        font-size: 2rem;
        color: #3c3737;
    }

    .appearance {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        img {
            box-shadow: 0px 0px 5px 0px rgba(0 0 0 / 0.3);
            border-radius: 8px;
            cursor: pointer;
            border: 3px solid transparent;
            max-width: 220px;

            &.gutscheinmotiv-checked {
                border: 3px solid #4fa085;
            }
        }
    }
}

@media screen and (max-width: 1120px) {
    #aa_detail.gutschein-seite {
        grid-template-areas: "header header" "center right" "left left";
        grid-auto-columns: 2fr 1fr;
    }
}
