/* ==========================================================================
07. ARTIKEL-DETAIL
========================================================================== */

#mengenKontrolle {
    display: flex;
    align-items: center;
    border: 2px solid #f8f8f8;

    > #mk_minus,
    > #mk_plus,
    > #mk_anz {
        border-radius: 0;
        height: 50px !important;
        border: none;
        color: #a2a2a2;
        font-size: 20px;
    }

    > #mk_minus,
    > #mk_plus {
        background: #f8f8f8;
        display: flex;
        justify-content: center;
    }

    > #mk_anz {
        box-shadow: none;
        text-align: center;
    }
}

// #staffelTabelle {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: center;
//     padding: 10px;
//     background: #f8f8f8;
//     font-size: 12px;
//     color: #9b9b9b;

//     .staffel_tab_eintrag {
//         text-align: center;
//         list-style-type: none;
//         border-right: 1px solid #e6e6e6;
//         padding: 8px 14px;

//         &:last-child {
//             border-right: none;
//         }

//         .staffel_preis {
//             font-weight: 700;
//             padding-top: 5px;
//         }
//     }
// }

#preisDiv {
    margin-bottom: 5px;

    &[data-ab]:before {
        content: attr(data-ab);
        font-size: 14px;
        padding: 0 10px 0 0;
        font-weight: 400;
        color: #9e9e9e;
    }
}

#aa_bewer #anzahl_bew {
    padding-right: 5px;
}

#storage-location {
    border-top: 2px solid #f1f1f1;
    border-bottom: 2px solid #f1f1f1;
    margin: 25px 0 0 0;
    padding: 20px;
    padding: 20px;
    font-size: 1.1rem;
    display: none !important;
}

#storage-location p {
    color: $helltext;
    margin: 0 0 10px 10px;
    display: flex;
    align-items: center;
}

#storage-location p:before {
    content: "";
    background: url(#{$cdnPfad}/icon_stores_g.svg) 0 0 no-repeat;
    width: 30px;
    height: 30px;
    background-size: cover;
    margin: 0 10px 0 0;
}

#storage-location div {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
}

#storage-location span.city {
    margin: 0 0 0 50px;
    color: $helltext;
    display: block;
}

.ad-benefit-img {
    display: flex;

    > img {
        max-height: 35px;
    }
}

@media (min-width: 760px) and (max-width: 1024px) {
    // #staffelTabelle {
    //     .staffel_tab_eintrag {
    //         border-right: none;

    //         &.wording {
    //             flex: 1 0 100%;
    //             border-bottom: 1px solid #e6e6e6;
    //         }
    //     }
    // }

    ul.cross_se li:nth-of-type(3) {
        display: none;
    }

    #storage-location p:before {
        display: none;
    }

    #storage-location span.city {
        margin: 0;
    }
}

@media (max-width: 760px) {
    #storage-location {
        font-size: 4.5vw;
    }
}

body #shopLayer[data-pp="aa"] header.fixed-header {
    position: relative;
    box-shadow: none;
}

#aa_pic_show {
    position: relative;

    &.has-iframe {
        .bewertungen {
            display: none;
        }
    }
}

#aa_pic_show nav .minipics a {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#aa_pic_show a.b_360.has360 {
    display: block;
}

#tZoomViewer {
    z-index: 700;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    width: 100% !important;
}

.artikel_image .rating {
    display: none;

    // lokal
    position: absolute;
    top: 0;
    right: 0;
    background: red;
    height: 10px;
    width: 10px;
}

.tZoom .controls {
    cursor: zoom-in;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;

    .left,
    .right {
        margin: 0 25px;
    }
}

.tZoom .controls b {
    cursor: pointer;
    background: #ffffff url(#{$cdnPfad}/b/nav_arrow_g.svg) 55% 50% no-repeat;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-size: 8px;
    border: 1px solid #acaaa5;
}

#aa_pic_show:hover .controls b {
    opacity: 0.7;
}

.tZoom .controls b:hover {
    opacity: 0.7;
}

.tZoom .controls b:focus {
    background-color: $color-primary;
}

.controls b.left {
    transform: rotate(180deg);
}

.wrapper_artikel-detail {
    margin: 25px 0;
    padding: 0 100px;
}

.wrapper_artikel-detail h1 {
    font-weight: 700;
    color: $color-secondary;
    font-size: 2.3rem;
    line-height: 100%;
}

.wrapper_artikel-detail h1 div {
    color: $helltext;
    font-size: 0.8rem;
    font-weight: 100;
    line-height: 200%;
    text-transform: initial;
}

.wrapper_artikel-detail figure.artikel_image {
    overflow: hidden;
    position: relative;
}

.wrapper_artikel-detail figure.artikel_image:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: $artikel-bg;
    position: absolute;
    z-index: 701;
    top: 0;
    left: 0;
    pointer-events: none;
}

.wrapper_artikel-detail .top_artikel {
    background: $artikel-bg;
    margin: 0;
}

.wrapper_artikel-detail .top_left,
.wrapper_artikel-detail .top_right,
.wrapper_artikel-detail .top_center {
    padding-bottom: 0;
}

.wrapper_artikel-detail .top_left img {
    margin: 10px 20px 0 10px;
    width: 100%;
    max-width: 100px;
    display: none;
}

.wrapper_artikel-detail .top_center img {
    margin: 25px auto;
    display: block;
}

.wrapper_artikel-detail .grid {
    align-items: flex-start;
    grid-column-start: 1;
    grid-column-end: 3;
}

.wrapper_artikel-detail .top_center {
    text-align: center;
    padding: 20px;
    display: none;
}

.wrapper_artikel-detail .top_center .anzBew {
    display: none;
}

.se_beobachten {
    margin: 20px 10px 0 0;
    box-sizing: border-box;
    cursor: pointer;
    background-image: url(#{$cdnPfad}/icon_fav.svg);
    -webkit-transition: background 0.5s;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-origin: content-box;
}

.se_beobachten.gemerkt {
    background-image: url(#{$cdnPfad}/icon_fav_rot.svg);
}

.top_right {
    position: relative;
}

.top_right .aa-proz {
    position: absolute;
    top: 78px;
    right: 0;
    font-size: 1.2rem;
    padding: 7px 11px;
    color: #fff;
    z-index: 701;
}

.rat_stars i,
.rat_stars b {
    background-image: url(#{$cdnPfad}/icon_star_full.svg);
    width: 30px;
    height: 30px;
    background-size: cover;
    display: inline-block;
}

.rat_stars u {
    background-image: url(#{$cdnPfad}/star-full.svg);
    width: 30px;
    height: 30px;
    background-size: cover;
    display: inline-block;
    opacity: 0.2;
}

.rat_stars {
    display: none !important;
}

.rat_stars b {
    background-image: url(#{$cdnPfad}/icon_star_half.svg);
}

.top_artikel .bewertungen {
    position: absolute;
    right: 10px;
    top: 10px;
}

.separate-rating .rat_stars i,
.separate-rating .rat_stars b,
.separate-rating .rat_stars u {
    width: 15px;
    height: 15px;
}

.separate-rating {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 20px 0;
    font-size: 0.8rem;
    border-bottom: 1px solid $grey-bright;
    align-items: center;
}

.separate-rating br {
    display: none;
}

.separate-rating b {
    font-size: 1rem;
}

.rating-text {
    flex: 1 0 100%;
    margin: 10px 0 10px 0;

    /*border-bottom: 1px solid #ddd;*/
    padding: 0 0 5px 0;
    font-size: 1rem;
}

.rating-comm {
    width: 100%;
    padding-bottom: 20px;
    box-sizing: border-box;
    border: 2px solid $color-primary;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
}

.rating-comm:empty {
    display: none;
}

.aa_rating_komm_ueb {
    width: 100%;
    display: block;
    text-align: center;
    padding: 0 0 5px 0;
    font-weight: 600;
}

.rating-properties {
    display: none;
}

span.rating-note {
    margin: 0 10px;
}

.wrapper_artikel-detail .top_right {
    text-align: right;
    display: flex;
    flex-direction: column;
    display: none;
}

.wrapper_artikel-detail .top_right * {
    align-self: flex-end;
}

.wrapper_artikel-detail figure .artikel-detail_img {
    width: 85%;
    display: block;
    margin: 2vw auto 3.3vw auto;
    position: relative;
    z-index: -1;
}

.wrapper_artikel-detail.lang figure .artikel-detail_img {
    margin: 0 auto 3vw auto;
}

.sonderpreis,
#preisDiv {
    font-size: 1.5rem;
    font-weight: 700;
    color: $color-secondary;
}

#preisDiv.sonderpreis {
    color: $rot;
    display: inline;
}

.d15_vk {
    text-decoration: line-through;
    color: $color-secondary;
    display: inline-block;
    margin: 0 0 0 15px;
}

.breadcrump {
    padding: 30px 0 20px 0;
}

.breadcrump span a {
    font-weight: 100;
    color: $color-primary;
    font-size: 14px;
}

.breadcrump span a:hover {
    color: $color-primary;
}

.artikel-detail_right .breadcrump {
    margin: 0 0 10px 0;
}

.breadcrump span > span {
    display: none;
}

.breadcrump span a + span {
    display: inline-block;
}

.artikel-detail_right span.breadcrum_arrow {
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.artikel-detail_right .size .fagrWrapper .fagr div {
    background: $dark;
    text-align: center;
    padding: 15px 2px;
    cursor: pointer;
    font-size: 0.9rem;
}

.fagrWrapper .fagr.weg {
    cursor: not-allowed;
}

.size .fagrWrapper .fagr.weg {
    opacity: 0.2;
}

.color .fagrWrapper .fagr.weg {
    cursor: not-allowed;
}

.color .fagr.col.weg > div {
    overflow: hidden;
}

.color .fagr.col.weg div div {
    opacity: 0.1;
}

.color .fagr.col.weg div div[data-name="weiß"] {
    opacity: 1;
}

.color .fagr.col.weg > div:after {
    content: "";
    display: block;
    border-top: 3px solid $rot;
    transform: rotate(-13deg) scale(1.1);
    margin-top: -17px;
}

.fagrWrapper.farben .fagr.active div div:after,
.size .fagrWrapper.farben .fagr.active div:after {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    border: 1px solid #777377;
    border-radius: 30px;
    padding: 5px;
}

.color .fagr.col {
    flex: 0 1;
}

.fagr.col[data-fa="5"] > div {
    border: 1px solid #ddd !important;
}

a.b_360 {
    width: 100px;
    height: 70px;
    display: block;
    margin-top: -70px;
    background: url(#{$cdnPfad}/b/icon_360.svg) 50% no-repeat;
    background-size: contain;
    z-index: 10;
    position: absolute;
    opacity: 0.5;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

a.yt-video {
    color: transparent;
    background: url(#{$cdnPfad}/b/Play-Button.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: initial !important;
}

.artikel_img figure iframe {
    width: 100%;
    border: none;
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    height: 100%;
}

.artikel_img iframe {
    height: 480px;
}

.size .fagrWrapper:empty:before {
    // content: $txt_groesse;
    display: block;
    width: 100%;
    margin: 0 0 6px 10px;
}

.size .fagrWrapper:empty:after {
    // content: $txt_onesize;
    display: block;
    margin: 0 0 20px 10px;
    background: $dark;
    padding: 15px 32px;
    border-bottom: 5px solid #fff;
    font-size: 0.8rem;
    box-shadow: 0 5px 0px 0px $color-primary;
}

.artikel-detail_right .color {
    margin: 30px 0 10px 0;
}

.artikel-detail_right .color .fagr > div {
    border: 1px solid transparent;
    cursor: pointer;
}

.artikel-detail_right .color .fagr > div[style*="background:#FFFFFF;"] {
    border: 1px solid #ddd;
}

.artikel-detail_right .color .fagr[data-fa="102"] > div {
    background: url(#{$cdnPfad}/multi_color.svg) 0 0 no-repeat;
}

.artikel-detail_right .color .fagr[data-fa=""] > div {
    background-image: url(#{$cdnPfad}/pattern_flecked.svg);
}

.artikel-detail_right .color .fagrWrapper .fagr div {
    border-radius: 30px;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.artikel-detail_right .color .fagrWrapper .fagr div div[style*="mehrfarbig"] {
    background-size: cover !important;
}

#farbname {
    display: inline-block;
}

div#groename {
    display: inline-block;
    margin: 0 0 0 8px;
}

.artikel-detail_right .color .fagr div div[data-name="weiß"]:before,
.artikel-detail_right .color .fagr div div[data-name="mint"]:before {
    color: $helltext;
}

.artikel-detail_right .color .fagr:hover div:before,
.artikel-detail_right .color .fagr.active div:before {
    opacity: 1;
    margin: 0;
    transition:
        opacity 300ms ease-in-out,
        margin 300ms ease-in-out;
}

.artikel-detail_right .color .fagr div[style*="background:#FFFFFF;"]:before {
    mix-blend-mode: darken;
    color: #ccc;
}

.artikel-detail_right .color .fagrWrapper .fagr div {
    height: 30px;
    width: 30px;
}

.artikel-detail_right .color .fagrWrapper .fagr div,
.artikel-detail_right .size .fagrWrapper .fagr div {
    position: relative;
}

.artikel-detail_right .size .fagrWrapper .fagr div {
    background: white;
    border: 2px solid #dddbdd;
    border-radius: 5px;
}

.artikel-detail_right .size .fagrWrapper .fagr.active div {
    border: 2px solid $color-primary;
}

.artikel-detail_right .color .title,
.artikel-detail_right .size .title {
    margin: 40px 0 10px 0;
}

div#groessenname:empty {
    display: none;
}

.size .title {
    display: flex;
    justify-content: space-between;
}

.size .title span {
    position: relative;
}

.size .title a {
    color: #666;
    text-decoration: underline;
    font-size: 1rem;
}

div#box_lagerinfo {
    text-align: center;
    padding: 15px 0;
}

div#box_lagerinfo div {
    color: $rot;
}

div#box_lagerinfo .groe_txt_st1 {
    color: $color-primary;
}

#inWKsenden,
.adet-item-watchlist {
    background: $color-primary;
    transition: background 800ms ease-in-out;
    color: #fff;
    border: none;
    width: 100%;
    min-height: 40px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
}

.adet-item-watchlist {
    background: $hellgrau;
    color: $color-primary;
    text-transform: uppercase;
}

#inWKsenden img,
.adet-item-watchlist svg {
    height: 30px;
    margin: 0 10px 0 -40px;
    opacity: 0;
    transition: all 300ms ease-in-out;
}

#inWKsenden:hover img,
.adet-item-watchlist:hover svg {
    opacity: 1;
    transition: all 300ms ease-in-out;
    margin: 0 10px 0 10px;
}

#inWKsenden:hover {
    background: $color-primary;
    transition: background 300ms ease-in-out;
}

div[style*="border: 2px solid RGB(231,76,60)"] {
    padding: 20px;
    border-radius: 30px;
}

.wrapper_artikel-detail .mwst,
.wrapper_artikel-detail .verkauf-durch {
    color: $color-secondary;
    font-size: 0.85rem;
    display: block;
}

.artikel-detail_right {
    background: #fff;
}

.sticky {
    position: sticky;
    top: 15px;
}

.artikel-detail_right .benefits {
    margin-top: 20px;
}

.benefits .col {
    margin: 0 0 10px 0;
}

.artikel-detail_right .benefits .se_banner {
    background: $dark;
    border-bottom: 3px solid $color-primary;
    padding: 10px 2px;
    text-align: center;
    font-size: 0.8rem;
    color: $helltext;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.vorteil div {
    flex-basis: 50%;
}

.se_banner.vorteil:before {
    content: "";
    background: url(#{$cdnPfad}/icon_pig.svg) 0 0 no-repeat;
    width: 50px;
    height: 50px;
    display: block;
}

.se_banner.vorteil.gratis_versand:before {
    background: url(#{$cdnPfad}/icon_pig.svg) 0 0 no-repeat;
}

.se_banner.vorteil.umtausch:before {
    background: url(#{$cdnPfad}/icon_exchange.svg) 0 0 no-repeat;
}

.se_banner.vorteil.lieferung:before {
    background: url(#{$cdnPfad}/icon_fast-delivery.svg) 0 0 no-repeat;
}

nav .minipics {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    margin: 20px 0;
    gap: 10px;
}

nav .minipics a {
    box-sizing: border-box;
}

nav .minipics a:before {
    content: " ";
    position: absolute;
    height: 100%;
    width: 100%;
    background: $artikel-bg;
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
}

#cs_layer .wk_img {
    position: relative;
}

#cs_layer a.button {
    text-align: center;
    &.cross-wk-button {
        width: 100%;
    }
}

@media (max-width: 768px) {
    #cs_layer .cross_se .artikel {
        line-height: unset;

        .cross-wk-button {
            padding: 5px;
        }
    }
}

#cs_layer a.button.wk {
    border-color: $color-primary;
    color: #fff;
    background: $color-primary;
}

#cs_layer h2 {
    text-align: center;
    padding: 30px 0 10px 0;
    font-family: "GT-America", sans-serif;
    font-size: 1.2rem;
}

#cs_layer .wk_img img {
    max-width: 100%;
    width: auto;
}

#cs_header span {
    font-size: 1.2rem;
}

nav .minipics a img {
    width: 100%;
    height: auto;
}

.ada_text {
    padding-bottom: 30px;
}

h2.erster_satz_artikeltext {
    font-size: inherit;
    font-family: inherit;
    color: #666;
}

h3.letzter_satz_artikeltext {
    font-size: 1rem;
    font-weight: 400;
    display: inline-block;
    margin: 0 0 20px 0;
}

h3.letzter_satz_artikeltext:before {
    content: " ";
    display: inline-block;
}

.wrap {
    margin: 0 auto;
}

.flex {
    display: flex;
}

.flex-1 {
    flex: 1 1;
}

.flex-change-1,
.flex-v {
    flex-direction: column;
}

.flex-change-2 {
    box-orient: horizontal;
    flex-direction: row;
}

.flex-center-axis {
    align-items: center;
}

.flex-sm-col-reverse {
    box-direction: reverse;
    flex-direction: column-reverse;
}

[role="site-info"] {
    background: #eee;
}

[role="header"] {
    background: #ff5b52;
    height: 40px;
}

[role="navigation"] {
    padding-bottom: 0;
}

[role="navigation"] ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

[role="navigation"] li {
    margin: 0;
    padding: 0;
}

[role="navigation"] li:last-child {
    padding-right: 0;
}

[role="navigation"] a {
    display: block;
    height: 35px;
    border-bottom: 2px solid $color-primary;
    color: $dunkeltext;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    font-family: Catamaran, Arial, sans-serif;
    border-top: 2px solid transparent;
    margin-left: -2px;
    font-size: 1.9vw;
}

[role="navigation"] a .site-title {
    text-align: center;
    color: white;
}

.page-title {
    text-align: center;
    font-weight: 900;
}

.tabs {
    position: relative;
}

.tabbed-container {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    padding-bottom: 20px;
    opacity: 0;
    height: 1px;
    overflow: hidden;
}

.tabbed-container.tabbed-container-active {
    position: relative;
    z-index: 2;
    opacity: 1;
    height: auto;
}

.tabbed-nav a.nav-active {
    border: 2px solid $color-primary;
    border-bottom: none;
}

li.flex-1:only-child a {
    border: none;
    border-bottom: 2px solid $color-primary;
    text-align: left;
    padding-left: 0;
    padding-bottom: 10px;
    background: #fff;
}

.tabbed-nav a:not(.nav-active) {
    background: $dark;
    color: $helltext;
}

.tabbed-container a.location_crumb.location_crumb2 {
    border: 2px solid #bdbdbd;
    padding: 10px 20px;
    margin: 10px;
    display: inline-block;
    color: #9b9b9b;
    cursor: pointer;
}

.tabbed-container a.location_crumb.location_crumb2:hover {
    border-color: $color-primary;
}

.tabbed-container a {
    min-width: 144px;
    margin: 20px 10px 0 0 !important;
    text-align: center;
}

h3.letzter_satz_artikeltext {
    font-size: 1rem;
    color: #666;
    font-weight: 500;
    font-family: inherit;
}

.artikel_properties,
.beschreibung,
#aa_cross .title,
#aa_serie .title,
#bew_wort {
    font-size: 1rem;
    padding: 40px 0 20px 0;
    font-family: "GT-America-Medium";
    text-transform: uppercase;
    display: block;
}

#aa_cross .title,
#aa_serie .title {
    padding: 60px 0 10px 0;
}

table#det_feat_table {
    width: 100%;
}

#det_feat_table tr {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.feat_dt_2 img {
    width: 40px;
}

#bewertungenAnzeigenButton {
    display: none;
}

img[src*="img/icon_serie.svg"] {
    width: 60px;
}

section[data-anz="0"],
section[data-anz=""] {
    display: none;
}

.feat_dt_2 img[src*="flag"] {
    width: 30px;
}

td[class*="feat_"] {
    color: $helltext;
    border-bottom: 1px solid $hellgrau;
    min-height: 50px;
    flex-basis: 24%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex: 1;
}

td.feat_dt_1 {
    flex-basis: 10%;
    padding: 8px 0;
    max-width: 130px;
}

td.feat_dt_2 {
    color: $dunkeltext;
    padding: 10px;
    max-width: 260px;
}

td.spacing {
    width: 4%;
}

td.feat_dt_1:empty {
    display: none;
}

td.feat_dt_1:empty + td.feat_dt_2:empty {
    display: none;
}

#det_feat_table tbody {
    display: flex;
    flex-wrap: wrap;
}

#det_feat_table tbody tr {
    flex: 0 0 100%;
    align-items: stretch;
}

#det_feat_table tbody tr.last {
    order: 10;
}

td.feat_dt_2:empty:after {
    content: " ";
    width: 30px;
    height: 30px;
    background: url(#{$cdnPfad}/icon_check.svg) 0 0 no-repeat;
    background-size: cover;
    display: block;
}

td.feat_dt_2 img[src*="img/MadeInChina.png"] {
    display: none;
}

.feat_dt_1.nocountry,
.feat_dt_1.nocountry + .feat_dt_2,
.feat_dt_1.nocountry + .feat_dt_2 + .spacing {
    display: none;
}

#cs_dark_matter {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.25);
    z-index: 23000;
    top: 0;
    left: 0;
    overflow: hidden;
    overscroll-behavior: none;
}

div#cs_layer {
    background: $hellgrau;
    box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.3);

    #weitersh {
        margin-right: 20px;
    }

    .wrapper_top {
        max-width: 960px;
        margin: 0 auto;
        padding: 40px;
    }

    .cross {
        background: #fff;
        width: 100%;
        padding-bottom: 60px;
    }

    ul.se.grid-center.cross_se {
        max-width: 960px;
        margin: 0 auto;
    }

    .wk_img {
        display: none;
    }
}

#cs_header {
    text-align: center;
}

#cs_header h1 {
    margin: 10px;
    font-size: 1.7rem;
}

#aa_bewer h3.title {
    &:hover:before {
        content: "Eine Überprüfung der Bewertungen hat wie folgt stattgefunden: Kunden erhalten einen Link zum Bewerten der Produkte. Es handelt sich dabei um einen individualisierten Link, den nur Verbraucher erhalten, die die Waren oder Dienstleistungen tatsächlich bei uns erworben haben.";
        position: absolute;
        top: 55px;
        width: 90%;
        margin-left: 2px;
        z-index: 1;
        background: #fff;
        font-size: 12px;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
    }
}

.wk_tab_summe hr {
    display: none;
}

@keyframes fadegrid {
    0% {
        transform: scale(0.4);
    }

    100% {
        transform: scale(1);
    }
}

#tipDiv {
    display: none !important;
}

.css_grid div {
    animation: fadegrid 300ms ease-out normal;
}

.css_grid div .basket {
    animation-delay: 200ms;
}

.css_grid div .artikel_info {
    animation-delay: 400ms;
}

// .artikel_more img.artikel-img {
//     max-height: 150px;
// }

.artikel_more figure {
    min-height: 220px;
}

.it_features_ul {
    list-style-type: none;

    &.with-images {
        margin: 20px 0 30px 0;
        display: flex;
        gap: 30px;

        a {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}

.bewertungen {
    h3 {
        font-family: "GT-America", sans-serif;
        font-size: 1.2rem !important;
        text-align: left !important;
        border: none !important;
    }

    .separate-rating b {
        order: 1;
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 200px;
        white-space: nowrap;
    }

    .separate-rating .rating {
        order: 3;
        flex-direction: row-reverse;
    }

    .rating {
        --percent: calc(var(--rating) / 5 * 100%);
        display: inline-flex;
        line-height: 1;
        align-items: center;
    }

    .rating[style="--rating: 0.00"] {
        display: none;
    }

    .rating-text {
        order: 4;
    }

    .separate-rating .rating:before {
        font-size: 16px;
    }

    .rating::before {
        content: "★★★★★";
        letter-spacing: 3px;
        background-clip: text;
        background: linear-gradient(90deg, $color-primary var(--percent), $grey-bright var(--percent));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    a.button {
        margin: 20px 0 0 0;
    }

    .unser-kommentar{
        order: 5;
        margin-left: auto;
    }

    .rating-kommentar {
        color: #3c3737;
        line-height: 20px;
        margin: 0px 0px 15px 20px;
        background: rgb(47, 128, 203);
        padding: 15px;
        border-radius: 5px;
        background: #f1eeea;
    }

    .unsere-antwort-head{
        font-weight: 600;
        margin-bottom: 5px;
    }
}

.vid-controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    z-index: 1000;
    transition: all 0.2s;

    .buttons {
        padding: 0 10px;
        height: 100%;

        button {
            background: none;
            border: 0;
            outline: 0;
            cursor: pointer;
            display: flex;
            width: 100%;
            height: 100%;
            align-items: flex-end;
            padding: 0;
            position: absolute;
            left: 0;
            bottom: 0;

            &:before {
                content: "\25B6";
                width: 30px;
                height: 30px;
                display: inline-block;
                font-size: 50px;
                color: $color-primary;
                line-height: normal;
                position: absolute;
                left: 10px;
                bottom: 10px;
            }

            &.play:before {
                content: "\25B6";
                font-size: 25px;
            }

            &.pause:before {
                content: "=";
                transform: rotate(90deg);
                transform-origin: right;
                bottom: 0;
            }
        }
    }
}

@media all and (max-width: 576px) {
    .bewertungen {
        .separate-rating {
            flex-direction: column;
            align-items: baseline;

            b {
                width: 100%;
            }

            .rating {
                padding-top: 5px;
                flex-direction: row !important;
            }
        }
    }

    .artikel_image .video3d {
        max-height: 300px;
    }

    .artikel_img iframe {
        height: 280px;
    }

    .artikel_more figure {
        min-height: 150px;
    }

    .artikel_more #aa_rating {
        display: none;
    }

    .artikel figure {
        padding: 10px !important;
        min-height: 0;
    }

    nav .minipics a {
        padding: 5px !important;
    }
}

@media all and (max-width: 510px) {
    nav .minipics{
        gap: 10px 5%;
    }
    #aa_pic_show nav .minipics a{
        height: 30% !important;
        width: 30% !important;
    }
}

@media all and (max-width: 1280px) {
    .wrapper_artikel-detail {
        padding: 0;
    }

    .artikel-detail_right .benefits .se_banner {
        flex-direction: row;
    }
}

/* _lg- */
@media all and (max-width: 1024px) {
    .artikel_img iframe {
        height: 340px;
    }

    #mengenKontrolle {
        > #mk_minus,
        > #mk_plus {
            max-width: 50px;
        }
    }

    // #staffelTabelle {
    //     .staffel_tab_eintrag {
    //         padding: 8px;
    //     }
    // }

    td.feat_dt_1 {
        max-width: initial !important;
    }

    li.men__h.head,
    ul.men_aug2.lvl_3 li.men_aug2_h.head {
        font-size: 1rem !important;
    }

    td[class*="feat_"] {
        -webkit-flex-basis: 50%;
    }

    td.spacing {
        display: none;
    }
}

@media all and (max-width: 768px) {
    .artikel-detail_right {
        position: relative;
    }

    #basket {
        margin-bottom: 50px;
    }
}
