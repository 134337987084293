/* ==========================================================================
19. FOOTER
========================================================================== */

#shopFooter {
    background: $hellgrau;
    border-top: 2px solid #fff;
}

.social {
    display: flex;
    gap: 40px;
    justify-content: center;

    img {
        height: 20px;
        width: auto;
    }
}

.footer-menu,
.footer {
    max-width: 1500px;
    margin: 0 auto;
}

.footer-menu div {
    display: flex;
    flex-direction: column;

    a {
        color: $color-secondary;
        padding: 10px 0;

        &:hover {
            color: $color-primary;
        }
    }
}

.footer_headline {
    font-size: 1.5rem;
    font-weight: 100;
    color: $color-secondary;
    border-bottom: 1px solid $color-secondary;
    display: block;
}

.footer.newsletter {
    .nl-headline {
        font-size: 1.5rem;
        font-weight: 100;
        color: #3c3737;
    }

    .nl-formular {
        display: flex;
        justify-content: center;
        align-items: stretch;

        #email,
        .button {
            border-radius: 0;
        }

        #email {
            height: unset !important;
            width: 300px;
        }
    }

    .nl-bedingungen {
        color: #777777;
        font-size: 12px;
        text-align: center;
        max-width: 500px;
    }
}

.payment li img {
    width: 100px;
}

.footer-menu .fb,
.footer-menu .insta {
    padding: 5px 0;
}

.fb-logo,
.insta-logo {
    display: flex;
    align-items: center;
}

.fb-logo img,
.insta-logo img {
    width: 30px;
    padding-right: 10px;
}

.fb-logo {
    color: #2b5e94;
    margin-top: 20px;
}

.insta-logo {
    color: #e4687f;
}

.footer-menu,
.footer-zahlung-versand {
    justify-content: center;
}

#se_to_top {
    display: none !important;
}

.footer {
    border-top: 1px solid $hellgrau;
    padding: 30px;
    box-sizing: border-box;
}

.footer.benefits,
.footer.newsletter {
    border-bottom: 2px solid #fff;
}

.footer .footer_headline {
    display: block;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 100;
    color: $color-secondary;
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
}

.footer .center_flex {
    margin: 30px 0;
}

.footer .svg_container {
    width: 40px;
    margin: 0 20px 0 0;
}

.footer.menu .footer_headline {
    text-align: left;
}

.footer.menu ul {
    list-style: none;
    margin: 20px 0 0 0;
}

.footer.menu ul + ul {
    margin: 0;
}

.footer.menu ul li {
    line-height: 170%;

    /*flex-basis: 100% !important;*/
}

.footer .social span {
    color: $helltext;
}

.social img {
    display: block;
    margin: 0 auto;
}

#shopFooter .full-width {
    background: #f1eeea;
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;

    > .wrapper {
        max-width: 1500px;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;

        .grid-middle-noGutter {
        }
    }

    .col-4.left {
        display: flex;
        gap: 10px;
    }
}

.payment div {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    img {
        width: 100px;
    }
}

.siegel img {
    width: 200px;
}

.footer_1 > div:not(.siegel) {
    flex-direction: column;
    gap: 20px;
    text-align: center;

    img {
        height: 70px;
    }
}

#shopFooter .full-width .footer {
    border: 0;
}

.footer_zahlungsarten,
.footer_versand {
    color: $helltext;
}

.full-width .footer span {
    font-size: 0.8rem;
    color: $helltext;
}

.footer.copyright nav ul li {
    display: inline-block;
    margin: 30px 20px 0 20px;
    font-size: 0.8rem;
}

.footer.copyright nav ul li a {
    color: $helltext;
}

.footer.copyright nav ul li a:hover {
    color: $color-primary;
}

#shopFooter nav#men_6 {
    background: transparent;
    padding: 0;
}

#shopFooter nav#men_6 a {
    padding: 0;
}

.footer_bottom {
    .left,
    .social,
    .right {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0 !important;
        flex-wrap: wrap;
    }

    .left a {
        padding: 0 5px;
    }
}

@media (max-width: 768px) {
    .footer .siegel {
        padding: 30px;
    }

    .footer_bottom .right {
        text-align: center;
    }
}

@media (max-width: 576px) {
    .footer.newsletter {
        .nl-formular {
            flex-wrap: wrap;

            #email,
            .button {
                width: 100%;
            }

            .button {
                margin-top: 10px;
            }
        }
    }
}
