#shopLayer[data-pp*="aar"] #shopMain {
    background: $hellgrau;
    max-width: unset;
    width: 100vw;

    .shop_tab_mitte {
        display: block !important;
    }

    .tab_k {
        font-size: 1.5rem;
        max-width: 750px;
        width: fit-content;
        margin: 40px auto 20px auto;
        padding: 30px 30px 0 30px;
        box-sizing: border-box;
    }
}

.rat-test {
    max-width: 750px;
    width: fit-content;
    margin: 0 auto 40px auto;
    background: #fff;
    box-sizing: border-box;
    padding: 0 30px 30px 30px;

    .ratt-top tr {
        display: flex;
        flex-direction: column;
        justify-content: center;

        td {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img {
            width: 204px;
            height: auto;
            margin: 0;
        }
    }

    form {
        input,
        textarea {
            width: 100%;
            border: 1px solid $grey-dark !important;
            padding: 10px;
            box-sizing: border-box;
            margin: 0 0 5px 0;
            border-radius: 5px !important;
            width: 30vw !important;
            min-width: 180px !important;
            max-width: 500px !important;
        }
    }
}

.cssTextBew {
    width: unset !important;
}

.cssBewName {
    font-size: 1rem;
    overflow: hidden;
    width: 110px;
    display: block;
    text-overflow: ellipsis;
}

form[name="ratt"] table tbody tr {
    display: flex;
    flex-basis: 100%;
    margin: 0 0 10px 0;
}

form[name="ratt"] table tbody tr td {
    min-width: 110px;
    margin: 0 10px 0 0;
    width: unset !important;
}

form[name="ratt"] table tbody tr:nth-child(1) td {
    width: 100%;
    margin: 0 0 20px 0;
}

form[name="ratt"] table tbody tr:nth-child(11),
form[name="ratt"] table tbody tr:nth-child(6),
form[name="ratt"] table tbody tr:nth-child(7),
,
form[name="ratt"] table tbody tr:nth-child(4) {
    display: none;
}

.rat-test input.cssBewSenden {
    background: $color-primary;
    border: none;
    color: #fff;
    padding: 10px 40px;
    font-size: 1rem;
    font-weight: 400;
    margin: 0 auto;
    display: block;
    max-width: 300px !important;
}

.separate-rating .rating-date {
    order: 2;
    opacity: 0;
    transition:
        visibility 0s,
        opacity 0.3s linear;
}

.separate-rating:hover .rating-date {
    display: inline-block;
    visibility: visible;
    opacity: 1;
}

@media all and (max-width: 600px) {
    form[name="ratt"] table tbody tr {
        flex-direction: column;

        td {
            text-align: left;
            width: 100% !important;
        }
    }

    .rat-test form input,
    .rat-test form textarea,
    .rat-test form select {
        width: 100% !important;
        max-width: unset !important;
    }
}
