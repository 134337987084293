$cdnPfad: "https://cdn.stetson.eu";

// Farben

$color-primary: #3f1e1f; // OXBLOOD  - Primary Button
$color-secondary: #3c3737; // CHARCOAL - Secondary Button

$grey-dark: #635f63; // SLATE
$grey-bright: #bcb9bd; // STEEL

$hellgrau: #f1eeea; // SILVERBELLY
$rot: #e90000; // Sale/Error
$green: #00b807; // Success

$dunkeltext: #453b3b; // Textfarbe dunkel
$helltext: #bcb9bd; // Textfarbe hell

$backgroundgrau: rgba(0, 0, 0, 0.03); // MUSS RAUS! DARF NICHT MEHR VERWENDET WERDEN!!!!!
$buttonborder: #bdbdbd; // MUSS RAUS! DARF NICHT MEHR VERWENDET WERDEN!!!!!
$dark: rgba(0, 0, 0, 0.03); // MUSS RAUS! DARF NICHT MEHR VERWENDET WERDEN!!!!!

$artikel-bg: rgba(101, 83, 37, 0.1);
