/* ==========================================================================
18. Startseite
========================================================================== */

.startseite {
    .banner_first {
        display: flex;
        justify-content: center;
    }

    .left {
        > img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .right {
        background-color: #f1eeea;
        width: 70%;
        padding: 4vh 7vw;
        text-align: center;
        box-sizing: border-box;
        align-items: center;
        display: flex;
    }
}

.startseite .border-top {
    border-top: 1px solid #ddd;
    padding-top: 30px;
}

.store-logo {
    padding: 12px;
    text-align: center;
}

.store-logo img {
    padding: 8px;
    height: 30px;
    max-width: 100%;
}

.unsere-stores {
    margin: 10px 0 60px 0;
    background: #f9f9f9;
    padding: 20px 0 15px 0;
}

.start_her {
    display: flex;
    background: $dark;
    padding: 20px;
    margin: 10px 0 60px 0;
    justify-content: space-around;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
}

.start_her a {
    opacity: 0.6;
    transition: all 200ms ease-in-out;
    display: block;
    flex-grow: 1;
}

.start_her a:hover {
    opacity: 1;
    transform: scale(1.2);
    transition: all 200ms ease-in-out;
}

.start_her img {
    width: 100%;
    height: auto;
    max-width: 110px;
    margin: 0 auto;
    display: block;
}

.startseite h3,
#shopLayer[data-pp*="htm"] h3 {
    text-align: left;
    font-size: 2rem;
    font-weight: 400;
    padding: 10px 0 20px 0;
    border-bottom: 1px solid #ddd;
    margin: 20px 0 30px 0;
}

.startseite h4 {
    text-align: center;
    font-size: 20px;
    margin: 15px 0;
    font-weight: 700;
}

.start_se {
    font-weight: 400;
    margin: 0 0 10px 0;
    font-size: 1.3rem;
    color: #999;
}

.start_se2 {
    font-weight: 400;
    margin: 0 0 10px 0;
    font-size: 1.1rem;
    color: #999;
}

.start_sep {
    font-size: 0.9rem;
    font-weight: 100;
    color: #9b9b9b;
}

.aug_menu {
    background: $dark;
    margin: 10px 0 60px 0;
}

.aug_menu nav ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 40px;
    flex-wrap: wrap;
}

.aug_menu li {
    flex-basis: 275px;
    margin: 10px 5px;
}

.aug_menu nav ul li a {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin: 0 20px 0 0;
    transition: all 200ms ease-in-out;
}

.aug_menu nav ul li a:hover {
    transform: scale(1.2);
    transition: all 200ms ease-in-out;
}

.aug_menu li a:before {
    text-align: center;
    content: "";
    width: 50px;
    height: 50px;
    margin: 0 15px 0 0px;
}

.aug_menu li.men_start_menu_1 a:before {
    background: url(#{$cdnPfad}/icon_hat.svg) 0 0 no-repeat;
    background-size: contain;
}

.aug_menu li.men_start_menu_2 a:before {
    background: url(#{$cdnPfad}/icon_beanie.svg) 0 0 no-repeat;
    background-size: contain;
}

.aug_menu li.men_start_menu_3 a:before {
    background: url(#{$cdnPfad}/icon_cap.svg) 0 0 no-repeat;
    background-size: contain;
}

.aug_menu li.men_start_menu_4 a:before {
    background: url(#{$cdnPfad}/icon_kopftuch.svg) 0 0 no-repeat;
    background-size: contain;
}

.aug_menu li.men_start_menu_5 a:before {
    background: url(#{$cdnPfad}/icon_schal.svg) 0 0 no-repeat;
    background-size: contain;
}

.aug_menu li.men_start_menu_6 a:before {
    background: url(#{$cdnPfad}/icon_handschuhe.svg) 0 0 no-repeat;
    background-size: contain;
}

@media (max-width: 1024px) {
    .startseite {
        .banner_first {
            flex-direction: column;

            .right {
                width: 100%;

                p {
                    margin-bottom: 20px;
                }
            }
        }
        .left {
            > img {
                margin-bottom: -4px;
            }
        }
    }
}

@media (max-width: 768px) {
    .seo-startseite {
        padding: 0 10px;
        columns: 1;
    }
}
