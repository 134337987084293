/* ==========================================================================
02. HEADER & MAIN NAVIGATION
========================================================================== */
.announcement-bar-bg {
    background-color: $hellgrau;
    padding: 5px 0;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
}

.announcement-bar-wrapper {
    &>div {

        white-space: nowrap;
        font-size: 14px;

        b {
            font-weight: bold;
        }

        &:nth-child(2) {
            text-align: center;
        }

        &:last-child{
            text-align: right;
        }
    }
}

@keyframes announcement-bar {
    // wird erst in der responsive.scss aufgerufen
    0% { transform: translateX(0); }
    20% { transform: translateX(0); }
    40% { transform: translateX(-100%); }
    60% { transform: translateX(-100%); }
    80% { transform: translateX(-200%); }
    100% { transform: translateX(-200%); }
}

header .logo {
    max-width: 100%;
    max-height: 40px;
    margin: 20px auto;
    display: block;
    min-width: 100px;
    transition: all 200ms ease-in-out;
}

#shopLayer[data-pp*="suchezkat"] header.fixed-header .logo,
#shopLayer[data-pp*="sucheaug"] header.fixed-header .logo {
    max-height: 30px;
    transition: all 200ms ease-in-out;
}

header {
    font-size: 0.7em;
    position: sticky;
    top: 0;
    z-index: 10000;
    background: #fff;
    transition: transform 0.3s ease-out;
}

header.hidden {
    transform: translateY(-100%);
}

#shopLayer[data-pp*="suchezkat"] header.fixed-header section.mainmenu,
#shopLayer[data-pp*="sucheaug"] header.fixed-header section.mainmenu {
    border: none;
}

header .wrapper {
    position: relative;
}

header .top-left {
    display: flex;
    align-items: flex-start;
    margin: 5px 0 0 0;
    padding: 0;
}

header .stores a {
    color: $color-primary;
}

header .top-left span a {
    display: flex;
}

header .top-left .stores {
    border: 1px solid $color-primary;
    border-radius: 24px;
    margin-right: 10px;
}

header .top-left .service {
    border: 1px solid $buttonborder;
    border-radius: 24px;
}

header .top-left > span {
    padding: 5px 15px;
}

header .stores a,
header .service a {
    /*display:flex;*/
    align-items: center;
    text-decoration: none;
    color: #333;
}

.sprache > img {
    max-width: 21px;
}

header .stores a {
    margin: 0;
}

header .stores:hover a {
    color: $color-primary;
}

header .stores:hover path {
    fill: $color-primary;
}

header .stores p,
header .service p {
    margin: 0 0 0 5px;
}

header .stores p {
    color: $color-primary;
}

header .menu-right {
    height: 100%;
    flex-wrap: nowrap;
}

header .menu-right * {
    display: flex;
    align-items: flex-end;
}

.menu-right .col-4 {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

header .menu-right span:not(.dot) {
    color: $helltext;
    margin: 10px 0 0 0;
    cursor: pointer;
    font-size: 0.7rem;
}

header .menu-right span {
    position: relative;
}

header .menu-search {
    align-content: center;
}

/*
header.fixed-header .menu-right span:not(.dot) {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  display: none;
}
*/

header.fixed-header .grid-spaceBetween {
    max-height: 75px;
}

header .menu-right svg {
    display: block;
    cursor: pointer;
    width: 25px;
    height: 25px;
    overflow: visible;
}

header .menu-right .col-xs:hover svg path {
    fill: $color-primary;
    transition: all 1s;
}

header .menu-right div:hover > span {
    transition: all 500ms ease-in-out;
    color: $color-primary;
}

.menu-right .fav {
    position: relative;
    // display: none;
}

header nav {
    position: relative;
    z-index: 702;
}

.header_top {
    max-width: 1500px;
    width: unset !important;
}

header nav div.submenu > ul,
header nav section.mainmenu > ul {
    display: flex;
    position: relative;
    z-index: 5;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
}

header nav div.submenu > ul {
    justify-content: space-between;
}

header nav .wrapper .nav {
    padding-bottom: 0;
    position: relative;
}

header nav ul li {
    list-style: none;
    text-transform: uppercase;
    padding: 0 0.4vw 0 0;
    margin-right: 2vw;
    color: $color-secondary;
}

/* nav ul li:hover div {
display: inline;
} */

header nav > ul > li::after {
    content: "";
    display: table;
    width: 100%;
    height: 3px;
    background-color: #fff;
}

section.mainmenu {
    display: flex;
    background: $color-primary;
    height: 50px;
}

/*
.mainmenu li:hover a.alvl_1:after {
    content: '';
    width: 15px;
    height: 20px;
    background: url(#{$cdnPfad}/icon_breadcrum_arrow_g.svg) 0 0 no-repeat;
    background-size: contain;
    display: inline-block;
    position: absolute;
    transform: rotate(90deg);
    top: 4px;
    right: -17px;
}

.mainmenu li.men_head_eb_1_3 a.alvl_1:after, 
.mainmenu li.men_head_eb_4_1_1 a.alvl_1:after {
transform: rotate(0deg);
    top: 11px;
}
*/

.search {
    position: absolute;
    right: 0;
    top: -45px;
    z-index: 1000;
}

.search.active {
    width: 100%;
    text-align: left;
    transition: all 0.2s ease-in-out;
    background: #fff;
    height: 50px;
    max-width: initial;
}

.search input {
    border: 2px solid #dddddd;
    height: 35px;
    border-radius: 35px;
    font-size: 1.1rem;
    padding: 0 0 0 15px;
    box-sizing: border-box;
    outline: none;
    transition: all 0.2s ease-in-out;
    position: absolute;
    right: 0;
    z-index: 6;
    width: 100%;
}

#shopLayer .search.active input {
    height: 50px !important;
    background: #fff;
    transition: all 0.2s ease-in-out;
    animation-direction: alternate;
}

.search input::placeholder {
    color: #bdbdbd;
    font-weight: 100;
}

.search::after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    background: url(#{$cdnPfad}/search-w.svg) 0 0 no-repeat;
    position: absolute;
    top: 7px;
    right: 16px;
    z-index: 7;
    pointer-events: none;
    background-size: contain;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.search.active::after {
    top: 10px;
    width: 25px;
    height: 25px;
    transition: all 0.2s ease-in-out;
}

header.fixed-header .search {
    display: none !important;
}

header nav .submenu ul li.hover li a:hover:after {
    transition: all 200ms ease-in-out;
    opacity: 1;
}

header nav ul li a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

header nav .mainmenu > ul > li {
    margin-top: 0;
    transition: margin 100ms ease;
    font-family: "GT-America-Medium";
}

header nav ul li div.submenu {
    position: absolute;
    left: 0;
    background: $hellgrau;
    width: 100%;
    box-sizing: border-box;
    transition: all 300ms ease-in-out;
    z-index: 1;
    box-shadow: none;
    visibility: hidden;
    opacity: 0;
}

header.fixed-header nav ul li div.submenu {
    top: 34px;
}

header nav .submenu ul li a {
    color: $color-secondary;
    font-size: 1em;
    white-space: nowrap;
    text-transform: initial;
    font-family: "GT-America", sans-serif;

    &.alvl_2 {
        margin-bottom: 20px;
    }
}

header nav ul li.hover ul.sub-extra li:hover a {
    transition: all 200ms ease-in-out;
    color: $color-primary !important;
}

header nav .submenu > ul > li a:hover {
    color: $color-primary !important;
}

header nav .submenu .sub-extra {
    margin: 20px 20px 5px 20px;
    border-top: 1px solid #6b6b6b;
    width: 200px;
    padding: 10px 0 0 0;
}

header nav .submenu .sub-extra li {
    font-size: 0.8rem;
    text-align: left;
    height: auto;
}

header nav .mainmenu ul li.hover div.submenu,
header nav .mainmenu ul li:active div.submenu {
    opacity: 1;
    pointer-events: all;
    width: 100%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    visibility: visible;
    gap: 50px;
    align-items: flex-start;
    justify-content: center;
}

header nav .aug_6 div.submenu > ul {
    max-height: 130px;
}

header nav li.hover div.submenu > ul {
    z-index: 1;
}

.submenu > ul > li {
    box-sizing: border-box;
    font-size: 1rem;
}

section.sub-submenu li.head {
    font-size: 1.2rem;
    font-weight: 100;
    margin: 20px 50px 7px 0;
    border-bottom: 1px solid #ddd;
    padding: 0 0 28px 0;
    display: block;
}

section.sub-submenu li.head:after {
    content: "";
    display: inline-block;
    width: 43px;
    height: 43px;
    margin: 0 0 0 10px;
    position: absolute;
    top: 12px;
    left: -11px;
}

li.men_aug_1 section.sub-submenu ul.formen li.head:after,
.men_head_eb_3_1 li.men_aug_1 section.sub-submenu ul li.head:after {
    background: url(#{$cdnPfad}/icon_hat.svg) 0 0 no-repeat;
    background-size: contain;
}

li.men_aug_2 section.sub-submenu ul.formen li.head:after,
.men_head_eb_3_1 li.men_aug_2 section.sub-submenu ul li.head:after {
    background: url(#{$cdnPfad}/icon_beanie.svg) 0 0 no-repeat;
    background-size: contain;
}

li.men_aug_3 section.sub-submenu ul.formen li.head:after,
.men_head_eb_3_1 li.men_aug_3 section.sub-submenu ul li.head:after {
    background: url(#{$cdnPfad}/icon_cap.svg) 0 0 no-repeat;
    background-size: contain;
}

li.men_aug_5 section.sub-submenu ul.formen li.head:after {
    background: url(#{$cdnPfad}/icon_accessoires.svg) 0 0 no-repeat;
    background-size: contain;
    width: 80px;
    height: 65px;
    top: -1px;
}

li.men_aug_5 section.sub-submenu ul.equipment li.head:after {
    background: url(#{$cdnPfad}/icon_equipment.svg) 0 0 no-repeat;
    background-size: contain;
    top: 8px;
}

header nav .mainmenu li.men_aug_5 .sub-submenu ul.equipment li:first-child {
    padding-left: 52px;
}

section.sub-submenu ul.material li.head:after {
    background: url(#{$cdnPfad}/icon_fabric.svg) 0 0 no-repeat;
    background-size: contain;
    top: 8px;
}

section.sub-submenu ul.witterung li.head:after {
    background: url(#{$cdnPfad}/icon_wetter.svg) 0 0 no-repeat;
    background-size: contain;
    top: 8px;
}

section.sub-submenu ul li {
    font-size: 0.7rem;
}

section.sub-submenu .lvl_3:empty {
    display: none;
}

header nav .submenu ul li div {
    height: auto;
    margin-top: 20px;
}

.sub-submenu ul li {
    text-align: left;
    color: $dunkeltext;
    padding: 0 0 4px 0;
}

header nav .sub-submenu ul li a {
    color: $dunkeltext;
}

.sub-submenu span {
    color: #333;
    text-align: left;
    display: block;
    font-size: 1.3rem;
    font-weight: 400;
}

.sub-submenu .grid {
    margin: 0 0 30px 0;
}

header nav .sub-submenu-wrapper {
    display: flex;
}

[data-online*="1"] header .login .st1 {
    stroke: $color-primary;
}

header .st1 {
    fill: transparent;
    stroke: #979797;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill-rule: evenodd;
}

header .menu-right div:hover > svg.ani-color path.st1 {
    transition: all 500ms ease-in-out;
    stroke: $color-primary;
}

/*Le Menu*/
section.sub-submenu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

section.sub-submenu > ul {
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: baseline;
    padding: 0;
}

section.sub-submenu > ul.formen {
    height: 100%;
}

section.sub-submenu > ul.witterung {
    order: 1;
}

.azu_112 {
    order: 1;
}

.aug_1 {
    order: 2;
}

.aug_2 {
    order: 3;
}

.aug_3 {
    order: 5;
}

.aug_5 {
    order: 6;
}

.aug_6 {
    order: 4;
}
.azu_10 {
    order: 7;
}

/*
section.sub-submenu>ul:nth-of-type(1) {
width:70%;
}

section.sub-submenu>ul:nth-of-type(2) {
width:30%;
}

section.sub-submenu>ul:nth-of-type(2) li {
width:50%;
}

section.sub-submenu>ul:nth-of-type(3) {
width:70%;
}

section.sub-submenu>ul:nth-of-type(4) {
width:30%;
}

section.sub-submenu>ul:nth-of-type(4) li {
width:50%;
}

*/
.men_head_eb_3_1 section.sub-submenu > ul {
    width: 50%;
}

section.sub-submenu > ul > li.head {
    width: 100%;
    height: 25px;
}

ul.material {
    order: 1;
}

.submenu > ul:first-child > li.hover > div {
    display: block;
}

header nav .submenu ul li .sub-submenu ul li a {
    border: 0;
    display: block;
    margin: 0 20px 0 0;
    font-size: 1.1rem;
    color: #777;
    position: relative;
}

header nav .submenu ul li a:hover,
header nav .submenu ul li .sub-submenu ul li a:hover {
    color: $color-primary;
    text-decoration: underline;
}

/*Le Menu*/

/* header .menu-right div:hover > svg.ani-shake {
animation: shake 300ms linear alternate;
} */

.submenu {
    display: none;
    position: absolute;
    background: $hellgrau;
    left: 0;
    right: 0;
    padding: 4vh 20vw;
    border-bottom: 10px solid $grey-dark;
}

.aug.hover .submenu {
    display: flex;
}

header nav div.submenu > ul {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 1000px;
    width: fit-content;
    border-right: 1px solid #000;
}

header nav div.submenu > ul:last-child {
    border-right: none;
}

#shopMain.openmenu:before {
    content: "";

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: 1000;
}

.stetson-logo {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}


.stetson-logo svg {
    width: 180px;
    max-width: none;
    height: auto;
}

@media screen and (max-width: 1024px) {
    .submenu {
        padding: 2vh 5vw;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(0, -1px, 0);
    }

    20%,
    80% {
        transform: translate3d(0, 1px, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(0, -1px, 0);
    }

    40%,
    60% {
        transform: translate3d(0, 1px, 0);
    }
}

.menu-wrapper {
    display: grid;
    grid-template-columns: 150px 1fr repeat(4, 30px);
    gap: 10px;
    align-items: center;

    .serach {
        cursor: pointer;
    }

    .sprache {
        position: relative;
        display: flex;
        align-self: flex-start;
        cursor: pointer;
        background: #fff;
        align-items: center;
        padding: 10px 0 !important;
        z-index: 1000;
        color: #979797;
        width: fit-content;
        padding: 10px 10px 10px 0 !important;

        .auswahl {
            padding-left: 5px;
        }

        .flaggen {
            display: none;
        }

        &:hover .flaggen {
            position: absolute;
            top: -3px;
            left: -13px;
            display: flex;
            gap:4px;
            flex-direction: column;
            z-index: 1000;
            background: #fff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
            padding: 10px;

            > a {
                display: flex;
                align-items: center;
                width: 30px;
                height:25px;
                justify-content: center;
                border-radius: 5px;

                &:hover {
                    background: #3f1e1f9e;
                    color: white;
                }

                > img {
                    max-width: 20px;
                }
            }
        }
    }
}

.stetson-logo svg {
    max-width: 180px;
    margin: 20px 0;
    transition: height 300ms ease-in-out;
}

.store-wrapper {
    flex: 0 1 auto;
    visibility: hidden;
}

#Basket path#Path {
    fill: $color-primary;
}

#wk-count[data-num="0"],
#wk-count[data-num=""],
#whishlist-count[data-num="0"],
#whishlist-count[data-num=""] {
    display: none;
}

@media (max-width: 1580px) {
    .header_top {
        margin: 0 70px !important;
    }
}

@media (max-width: 1024px) {
    header .wrapper {
        &.suche {
            display: none;
        }
    }
}

@media (max-width: 768px) {
    .stetson-logo svg {
        margin: 10px 0;
    }

    .head-right {
        margin-right: 10px;
    }
    .menu-wrapper .sprache {
        align-self: center;
        padding: 0 !important;
        padding-right: 20px !important;
    }

    .sprache:hover .flaggen {
        position: absolute;
        top: 20px !important;
    }
}

@media (max-width: 576px) {
    .menu-search {
        button.mobilizedMenue {
            width: 30px;
            height: 30px;
        }

        .mobile_search img {
            max-width: 20px;
        }
    }

    header .menu-right {
        margin-right: unset;
    }

    .mobile_search {
        margin-left: 4px;
    }
}

@media (max-width: 425px) {
    .stetson-logo svg {
        max-width: 130px;
    }

    .menu-right {
        .fav,
        .login,
        .warenkorb {
            svg {
                // height: 20px;
            }
        }
    }
}

@media (max-width: 768px) {
    .menu-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 10fr 1fr 1fr;

    }

    .stetson-logo {
        order: 3;

        svg {
            width: 120px;
        }
    }

    .search {
        order: 1;
    }

    .fav {
        order: 2;
    }

    .login {
        display: none;
    }

    .warenkorb {
        order: 4;
    }

    .mobile_menu {
        order: 5;
    }

}
