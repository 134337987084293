/* ==========================================================================
06. _BANNER
========================================================================== */

.sprachauswahl {
    gap: 10px;

    img {
        max-width: 30px;
        width: 100%; // sonst fehlt es, warum auch immer ... f*** svg
    }

    /* GB/UK Logo temporär ausblenden, bis Tobi den Sprachen fix (GB => UK) eingespielt hat */
    .gb {
        display: none;
    }
}

.select-language {
    text-align: center;
    padding-bottom: 10px;
}

.start_banner_benefits {
    background: $dark;
    padding: 10px 20px;
    justify-content: space-around;
    overflow: hidden;
    position: relative;
    margin: 0 0 10px 0 !important;
}

.start_banner_benefits span.close_this {
    cursor: pointer;
    color: #61a395;
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 0px;
    margin: 0;
    transform: none;

    &:after {
        display: inline-block;
        content: "\00d7"; /* This will render the 'X' */
    }
}

.start_banner_benefits .col {
    font-size: 0.8rem;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.start_banner_benefits .col:before {
    content: "";
    width: 50px;
    height: 50px;
    display: inline-block;
    margin: 0 12px 0 10px;
}

.start_banner_benefits .col span {
    margin: 0 10px 0 0;
}

.start_banner_benefits .col.free:before {
    //background: url(#{$cdnPfad}/icon_pig.svg) 50% 50% no-repeat;
    background: url(#{$cdnPfad}/b/benefit-free-shipping-xmas.svg) 50% 50% no-repeat;
    background-size: contain;
}

.start_banner_benefits .col.fast:before {
    //background: url(#{$cdnPfad}/icon_fast-delivery.svg) 50% 50% no-repeat;
    background: url(#{$cdnPfad}/b/benefit-fast-delivery-xmas.svg) 50% 50% no-repeat;
    background-size: contain;
}

.start_banner_benefits .col.long:before {
    //background: url(#{$cdnPfad}/icon_exchange.svg) 50% 50% no-repeat;
    background: url(#{$cdnPfad}/b/benefit-exchange-xmas.svg) 50% 50% no-repeat;
    background-size: contain;
}

.banner {
    .sale {
        background: #aaaeb1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 5rem;
        height: 100%;
        font-family: Lora, serif;
    }
}

.banner-logo.left-bottom {
    left: 30px;
    bottom: 20px;
}

.banner-logo.left-top {
    left: 30px;
    top: 20px;
}

.banner-logo.right-bottom {
    right: 30px;
    bottom: 20px;
}

.banner-logo.right-top {
    right: 30px;
    top: 20px;
}

.banner-text .show {
    display: none;
}

.banner-text {
    columns: 250px 2;
    column-gap: 30px;
    text-align: justify;
    line-height: 22px;
    font-size: 15px;
    color: #4a4a4a;
}

.text-winter,
.geschaeftsfuehrer {
    color: #4a4a4a;
}

.banner-header {
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    color: #4a4a4a;
}

.banner-magazin {
    padding-bottom: 0;
}

.banner-header h3 {
    font-size: 40px;
    padding-bottom: 8px;
}

.banner.hersteller {
    cursor: default;
}

.banner.hersteller:hover {
    box-shadow: 0;
    transform: scale(1);
}

.banner.hersteller:hover img {
    box-shadow: none !important;
}

.banner.hersteller div {
    background: $dark;
    height: 100%;
    width: 100%;
    display: flex;
}

.banner.hersteller img {
    margin: 0 auto;
    width: 80%;
}

.banner {
    position: relative;
    width: 100%;
    box-shadow: none;
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
    opacity: 0;
    transition: opacity 1500ms ease-in-out;
}

.banner.geladen {
    opacity: 1;
    transition: opacity 1500ms ease-in-out;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.banner a {
    &.border-none {
        border: none;
    }

    &.background-none {
        background: none;
    }
}

.banner a {
    position: relative;
    width: 100%;

    > span {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.2) 100%);
        min-height: 50px;
        align-items: center;
        width: 100%;
        padding: 2rem;
        box-sizing: border-box;
        font-size: 2rem;
        line-height: 25px;
        color: #fff;
        font-weight: 400;
        font-family: Lora, serif;

        &.primary {
            min-height: 100px;
            font-size: 3rem;
            line-height: 110%;
            padding: 2rem;
        }
    }
}

.banner h2 {
    padding: 10px 10px 20px 20px;
    color: #fff;
    font-weight: 200;
    font-size: 3rem;
    max-width: 70%;
}

.banner-headline {
    width: 100%;
}

.banner img {
    max-width: 100%;
    height: auto;
}

.banner:hover img {
    transition: box-shadow 200ms ease-in-out;

    /* box-shadow: 0px 0px 23px rgba(0,0,0,0.1); */
}

.banner > a > div {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    line-height: initial;
    margin-bottom: -56px;
}

.banner div div {
    color: #fff;
    box-sizing: border-box;
    background-color: $color-primary;

    div {
        padding: 20px;
    }
}

.banner div div h3 {
    font-size: 2rem;
    font-weight: 400;
    position: relative;
    margin: 0 0 0 -1px;
    border: none;
    text-align: left;
    padding: 0;
    color: #fff;
}

.banner {
    opacity: 1;
}

img.banner-img {
    width: 100%;
    height: 100%;
    margin-top: -65px;
    aspect-ratio: 2/1;
}

.banner-logo {
    position: absolute;
    width: 20%;
    padding: 20px;
}

.banner-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 0 !important;
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.2) 100%) !important;
}

.banner_oben {
    grid-row-start: 1;
    grid-column-end: 3;
    grid-column-start: 1;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    order: -1;

    .banner_top {
        display: flex;
        justify-content: center;
        background: #f1eeea;

        &.no-image .right {
            max-width: 100vw;

            * {
                max-width: 1100px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    img {
        margin-top: 0;
        object-fit: cover;
    }

    .left {
        line-height: 0;
        background-color: $hellgrau;
    }

    .right {
        background-color: $hellgrau;
        width: 70%;
        padding: 4vw 7vw;
        text-align: center;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            font-size: 2.5rem;
            text-transform: uppercase;
        }
    }
}

@media screen and (max-width: 576px) {
    .start_banner_benefits {
        .col:before {
            display: none;
        }
    }

    .banner a {
        position: relative;
        width: 100%;
    }

    .banner div div h3 {
        font-size: 1.4rem;
    }
}

/*------------------------------------ Banner größen ---------------------------------------------------------------------------*/

.b1 {
    flex-basis: 25%;
    max-width: 25%;
}

.b2 {
    flex-basis: 66.66%;
    max-width: 66.66%;
    padding: 0 0.5rem 1rem;
}

.b3 {
    flex-basis: 75%;
    max-width: 75%;
}

/*** Sale-Banner animiert auf Startseite ***/

.headline--jump {
    .char {
        --name: jump;
        --dur: 800ms;
        --del: calc(var(--char-index) * 0.075s);
        --tf: cubic-bezier(0.165, 0.44, 0.64, 1);
    }

    @keyframes jump {
        20% {
            transform: translateY(2%) scaleY(0.9);
        }

        40% {
            transform: translateY(-100%) scaleY(1.2);
        }

        50% {
            transform: translateY(10%) scaleY(0.8);
        }

        70% {
            transform: translateY(-5%) scaleY(1);
        }

        80%,
        100% {
            transform: translateY(0) scaleY(1);
        }
    }
}

@-webkit-keyframes bounce {
    100% {
        top: -20px;
        text-shadow:
            0 1px 0 #ccc,
            0 2px 0 #ccc,
            0 3px 0 #ccc,
            0 4px 0 #ccc,
            0 5px 0 #ccc,
            0 6px 0 #ccc,
            0 7px 0 #ccc,
            0 8px 0 #ccc,
            0 9px 0 #ccc,
            0 50px 25px rgba(0, 0, 0, 0.2);
    }
}

@keyframes breathe {
    0% {
        font-variation-settings:
            "wght" 100,
            "wdth" 85;
        font-size: 5vw;
    }

    60% {
        font-variation-settings:
            "wght" 700,
            "wdth" 100;
        font-size: 8vw;
    }

    100% {
        font-variation-settings:
            "wght" 100,
            "wdth" 85;
        font-size: 5vw;
    }
}

@media (max-width: 576px) {
    #banner-animated {
        min-height: 250px;

        > span {
            font-size: 100px;
        }
    }
}

@media (max-width: 768px) {
    .banner_oben .right {
        h2 {
            font-size: 1.8rem;
        }

        p {
            font-size: 14px;
        }
    }
}

@media (max-width: 1024px) {
    .banner_oben {
        .right {
            width: 100%;
        }
    }
}

@media (max-width: 80em) {
    .b1 {
        flex-basis: 33.33333%;
        max-width: 33.33333%;
    }

    .b2 {
        flex-basis: 66.66667%;
        max-width: 66.66667%;
    }

    .b3 {
        flex-basis: 100%;
        max-width: 100%;
    }
}

@media (max-width: 64em) {
    .b1 {
        flex-basis: 50%;
        max-width: 50%;
    }

    .b2 {
        flex-basis: 100%;
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    .banner a {
        > span {
            &.primary {
                font-size: 2rem;
            }
        }
    }

    .banner_oben .banner_top {
        flex-direction: column;

        .left img {
            min-width: 0;
        }

        .right {
            max-width: 100vw;
            box-sizing: border-box;
        }
    }
}

@media (max-width: 1280px) {
    img.banner-img {
        margin-top: 0;
    }

    .b2 {
        flex-basis: 100%;
        max-width: 100%;
        margin: 0 0 40px 0 !important;
    }
}

/* Artikelverlinkungen auf Bannern */

.artikelverlinkung:not(*[href]),
.artikelverlinkung[href=""] {
    display: none;
}

.av-container {
    position: relative;
    display: inline-block;
}

.startseite .av-container {
    margin: 0 auto;
}

a.pulsating-circle {
    position: absolute;
    left: 72%;
    top: 16%;
    transform: translateX(-50%) translateY(-50%);
    width: 20px;
    height: 20px;
    display: block;
    z-index: 1;
}

.av-container:hover a.pulsating-circle:before,
a.pulsating-circle.active:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: $color-primary;
    -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

a.pulsating-circle:hover:before {
    animation: none !important;
    -webkit-animation: none !important;
    background-color: transparent !important;
}

a.pulsating-circle:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 15px;
    cursor: pointer;
    white-space: nowrap;
}

.av-container:hover a.pulsating-circle:after,
a.pulsating-circle.active:after {
    -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: background-color 200ms ease-in-out;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.av-container a.pulsating-circle:hover:after {
    content: $zumArtikel;
    animation: none !important;
    -webkit-animation: none !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 15px;
    box-sizing: border-box;
    transition: all 300ms ease-in-out;
    background-color: #bdbdbd;
    color: $color-primary;
    border-radius: 5px;
}

@-webkit-keyframes pulse-ring {
    0% {
        transform: scale(0.33);
    }

    80%,
    100% {
        opacity: 0;
    }
}

@keyframes pulse-ring {
    0% {
        transform: scale(0.33);
    }

    80%,
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes pulse-dot {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}

@keyframes pulse-dot {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}

@media (max-width: 768px) {
    .av-container a.pulsating-circle:after,
    a.pulsating-circle.active:after {
        -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        width: 100%;
        height: 100%;
        background-color: #fff;
        transition: background-color 200ms ease-in-out;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    }

    .av-container a.pulsating-circle:before,
    a.pulsating-circle.active:before {
        content: "";
        position: relative;
        display: block;
        width: 300%;
        height: 300%;
        margin-left: -100%;
        margin-top: -100%;
        border-radius: 45px;
        background-color: $color-primary;
        -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    .av-container a.pulsating-circle:hover:after {
        content: none;
    }
}

/* Artikelverlinkungen auf Bannern -ende-*/

.banner.b1x1 {
    display: flex;
    flex-direction: column;

    .img-container {
        position: relative;

        img {
            width: 100%;
            height: auto;
            aspect-ratio: 1/1;
            display: block;
        }
    }

    .banner-text-over {
        position: absolute;
        background: transparent;
        color: #fff;
        top: 10%;
        text-align: center;
        padding: 0 10%;
        width: 100%;
        box-sizing: border-box;
        font-size: clamp(1.3rem, 2.1vw, 40px);
        font-family: 'Lora';
    }

    .button {
        width: auto !important;
        display: inline-block;
        text-align: center;
    }

    .b1x1_bottom {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }
}
