.start_top {
    background-color: $artikel_bg;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 3vh 10vw;
        box-sizing: border-box;

        * {
            align-self: center;
        }
    }

    h2 {
        font-size: 3vw !important;
        line-height: 100%;
    }
}

.start_img img {
    max-width: 100%;
    height: auto !important;
}

.start_wrapper {
    max-width: 1500px;
    margin: 0 auto;

    .button {
        margin-top: .5rem;
    }
}

.start_artikel ul {
    margin: 0;
}

.startseite {
    img {
        max-width: 100%;
        height: auto;
    }

    section {
        margin: 0 0 10vh 0;
    }

    h2 {
        font-size: 2.5rem;
        text-transform: uppercase;
        margin-bottom: 0.7vw;
    }

    p {
        max-width: 850px;
        margin-bottom: 40px;
    }

    .center p {
        margin-left: auto;
        margin-right: auto;
    }
}

.seo-startseite {
    margin: 0 auto;
    max-width: 1500px;
    columns: 2;
    padding: 0 20px;

    h1 {
        column-span: all;
        margin: 0 0 30px 0;
        font-size: 2rem;
        font-family: "GT-America-Bold",sans-serif;
    }

    p {
        margin-bottom: 0;
    }
}


@media (max-width: 1024px) {
    .start_wrapper .col-3_md-6 {
        margin-bottom: .75rem;
    }
}